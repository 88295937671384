/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { loadStripeTerminal } from '@stripe/terminal-js';
import i18n from 'i18next';
import { io } from 'socket.io-client';
import { isEqual, countBy, findLastIndex, sortBy, debounce, cloneDeep, groupBy } from 'lodash';
import {
  Table,
  Grid,
  Search,
  Dropdown,
  Pagination,
  Loader,
  Modal,
  Button,
} from 'semantic-ui-react';
import moment from 'moment';
import { useMediaQuery } from '@react-hook/media-query';
import ReactGridLayout from 'react-grid-layout';
import gridViewIcon from './icons/gridview.png';
import listViewIcon from './icons/listview.png';
import summaryIcon from './icons/summary.png';
import newbookingIcon from './icons/newbooking.png';
import refreshIcon from './icons/refresh.png';
import readerIcon from './icons/reader.png';
import mergeIcon from './icons/merge2.png';
import printerIcon from './icons/printer.png';
import tableIconUnselected from './icons/table-unselected.png';
import { useStateValue } from '../../../core/context/StateProvider';
import {
  dropdownOptions,
  getBookingsData,
  getSearchData,
  getBookingsStatusData,
  refundCard,
  refundCash,
  refundLater,
  changeStatus,
  getBookingsPaymentTypeData,
  refundCashPin,
  refundCardPin,
  refundLaterPin,
  changeStatusPin,
  getBookingsDataEmployee,
  getBookingsTypeTypeData,
  getBookingsByTableData,
  getBookingsByTableDataEmployee,
  getBookingsTypeTypeDataEmployee,
  getBookingsPaymentTypeDataEmployee,
  getBookingsStatusDataEmployee,
  getSearchDataEmployee,
} from './BookingsScreenData';
import {
  closeAllBookings,
  closeAllBookingsPin,
  editCardBooking,
  editCardBookingPin,
  editCashBooking,
  editCashBookingPin,
  editLaterBooking,
  editLaterBookingPin,
  splitBooking,
  splitBookingPin,
  updateTableOfBooking,
  updateTableOfBookingPin,
} from '../../../api/BookingAPI';
import './BookingsScreen.css';
import { asCurrency } from '../../../utils/NumberFormatter';
import BookingsScreenModal from './BookingsScreenModal';
import ErrorWarningModal from '../../../components/general/ErrorWarningModal/ErrorWarningModal';
import BookingsTerminalReaderModal from './BookingsTerminalReaderModal';
import { Printer } from '../../../utils/Printer';
import DailySummaryModal from './DailySummaryModal';
import BookingsCreateModal from './BookingsCreateModal';
import {
  acceptQRRequest,
  acceptQRRequestPin,
  createTerminalReaderConnectionToken,
  declineQRRequest,
  declineQRRequestPin,
} from '../../../api/PaymentAPI';
import BookingsCreateModalMobile from './BookingsCreateModalMobile';
import PinNumPad from '../../../components/shop/PinNumPad/PinNumPad';
import { checkPinOnly } from '../../../api/UserAPI';
import { getMe } from '../../../api/AppInfoAPI';
import BookingsMergeModal from './BookingsMergeModal';
import { showToast } from '../../../components/general/Toast/Toast';
import { PrinterQueue } from '../../../utils/PrinterQueue';
import FailedPrintsModal from './FailedPrintsModal';
import { usePrintableLogo } from '../../../utils/LogoHook';

const BookingsScreen = () => {
  const [{ token, shop, user, config }, dispatch] = useStateValue();
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [refreshRequired, setRefreshRequired] = useState(false);
  const [refreshFromModalNeeded, setRefreshFromModalNeeded] = useState(false);
  const [isGettingBookings, setIsGettingBookings] = useState(false);
  const [selectedDropdownOptions, setDropdownOptions] = useState('');
  const [failedPrintsModalOptions, setFailedPrintsModalOptions] = useState({ open: false });
  const [dailySummaryModalOptions, setDailySummaryModalOptions] = useState({
    open: false,
    role: '',
    pin: '',
  });
  const [bookingsMergeModalOptions, setBookingsMergeModalOptions] = useState({ open: false });
  const [bookingsCreateModalOptions, setBookingsCreateModalOptions] = useState({
    open: false,
    terminal: null,
    table: null,
    bookingId: null,
    isDiscoveringTerminal: false,
  });
  const [pinOptions, setPinOptions] = useState({ open: false, onSendPin: null });
  const [isDiscoveringTerminal, setIsDiscoveringTerminal] = useState(false);
  const [isBookingsAvailable, setIsBookingAvailable] = useState(true);
  const [selectedRoomName, setSelectedRoomName] = useState(null);
  const [selectedRoomIndex, setSelectedRoomIndex] = useState(null);
  const [roomsDropdownOptions, setRoomsDropdownOptions] = useState([]);
  const [currentStatus, setCurrentStatus] = useState('');
  const [currentPaymentType, setCurrentPaymentType] = useState('');
  const [currentTypeType, setCurrentTypeType] = useState('');
  const [currentTable, setCurrentTable] = useState(null);
  const [showGridView, setShowGridView] = useState(!!(user && user.role === 'employee'));
  const [showTableView, setShowTableView] = useState(false);
  const [isDiscoverTerminalBeingCalled, setIsDiscoverTerminalBeingCalled] = useState(false);
  const [isTerminalReaderConnectionPossible, setIsTerminalReaderConnectionPossible] = useState(
    true
  );
  const [failedPrints, setFailedPrints] = useState([]);
  const [preferredTerminalToConnect, setPreferredTerminalToConnect] = useState(null);
  const [stripeTerminal, setStripeTerminal] = useState(null);
  const [isLoadingBookings, setIsLoadingBookings] = useState(false);
  const [otherRoomWithNewBookings, setOtherRoomWithNewBookings] = useState(false);
  const [bookingsListModalOptions, setBookingsListModalOptions] = useState({
    open: false,
    bookings: [],
    table: null,
  });
  const [tableData, setTableData] = useState({
    bookings: [],
    selectedColumn: null,
    sortDirection: 'descending',
    totalPages: 0,
    currentPage: 1,
    isFiltering: false,
    isSearching: false,
    searchTerm: '',
    rooms: [],
  });
  const [modalOptions, setModalOptions] = useState({
    open: false,
    booking: null,
    closeEditMode: false,
  });
  const [terminalReaderModalOptions, setTerminalReaderModalOptions] = useState({
    open: false,
    terminals: [],
    connectedReader: null,
    isConnectingToTerminal: false,
  });
  const [discoveredTerminals, setDiscoveredTerminals] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [warningModalOptions, setWarningModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
    amount: null,
  });
  const [errorModalOptions, setErrorModalOptions] = useState({
    open: false,
    title: '',
    message: '',
    ok: false,
    onOk: null,
    yes: false,
    onYes: null,
    no: false,
    onNo: null,
  });
  const [errorWarningModalOptions, setErrorWarningModalOptions] = useState({
    open: false,
    message: '',
  });
  const { logoCanvasContext, logoError } = usePrintableLogo(config.cloudinaryUrlPrefix + shop.logo);
  const matches = useMediaQuery('only screen and (min-width: 1000px)');
  const mobileMatches = useMediaQuery('only screen and (min-width: 768px)');

  const promiseWithTimeout = async (promise, timeout, booking) => {
    let timer;
    const timeoutPromise = new Promise((_, reject) => {
      timer = setTimeout(() => {
        reject(new Error('Promise timed out'));
        showToast({
          error: true,
          message: `${i18n.t('orderTitle')} ${booking.bookingId}${i18n.t('couldNotBePrinted')}`,
          title: i18n.t('newPrinterErrorTitle'),
          id: `${booking.bookingId}2`,
        });
      }, timeout);
    });

    return Promise.race([promise, timeoutPromise]).finally(() => {
      clearTimeout(timer);
      if (localStorage.getItem('failedPrints')) {
        const failedPrints = JSON.parse(localStorage.getItem('failedPrints'));
        setFailedPrints([...failedPrints]);
      }
    });
  };

  const printBooking = (printjob) => {
    if (printjob.error && printjob.errorMessage) {
      setErrorWarningModalOptions({
        open: true,
        message: printjob.errorMessage,
      });
      return Promise.reject(new Error(printjob.errorMessage));
    }
    showToast({
      loading: true,
      message: `${i18n.t('orderTitle')} ${printjob.bookingId}${i18n.t('wirdGedruckt')}`,
      id: printjob.bookingId,
    });

    const printPromise = new Promise((resolve, reject) => {
      const printer = new Printer({
        ipAddress: printjob.printerIpAddress,
        deviceId: printjob.printerDeviceId,
        description: printjob.printerName,
      });
      printer.epos.onerror = () => {
        showPrinterError(printer);
        reject();
      };
      printer.epos.onreceive = (res) => {
        if (res.success) {
          resolve();
        } else {
          showPrinterError(printer);
          reject();
        }
      };
      if (printjob.type === 'category') {
        printer.printCategory(printjob.bookedItems, printjob, printjob.room);
      } else {
        printer.printInternal(printjob, printjob.room);
      }
    });
    return promiseWithTimeout(printPromise, 30000, printjob);
  };

  if (
    user &&
    user.role === 'manager' &&
    user.isPrinting === true &&
    shop &&
    shop.autoPrint === true
  ) {
    const [isSocketConnected, setIsSocketConnected] = useState(false);
    const [printQueue] = useState(new PrinterQueue(printBooking));

    const onSocketConnect = () => {
      setIsSocketConnected(true);
      console.info('WS CONNECTED');
    };

    const onSocketDisconnect = () => {
      setIsSocketConnected(false);
      console.info('WS DISCONNECTED');
    };

    const onSocketError = (error) => {
      setIsSocketConnected(false);
      console.error('WS ERROR: ', error);
    };

    const onNewPrintJob = async (data) => {
      if (data && data.bookedItems && data.bookedItems.length && data.bookedItems.length > 0) {
        await printQueue.addToQueue(data);
      }
    };

    useEffect(() => {
      const socket = io(process.env.REACT_APP_SERVER_URL, {
        transports: ['websocket'],
        autoConnect: false,
        auth: (cb) => {
          cb({ shopId: shop._id, offset: localStorage.getItem('lastPrintedPrintjobId') });
        },
      });
      if (isSocketConnected === false) {
        console.log('connecting');
        socket.connect();
        socket.on('connect', onSocketConnect);
        socket.on('disconnect', onSocketDisconnect);
        socket.on('printjob', onNewPrintJob);
        socket.on('connect_error', onSocketError);
      }

      return () => {
        socket.off('connect', onSocketConnect);
        socket.off('disconnect', onSocketDisconnect);
        socket.off('printjob', onNewPrintJob);
        socket.off('connection_error', onSocketError);
        setIsSocketConnected(false);
        socket.disconnect();
      };
    }, []);
  }

  useEffect(() => {
    if (isGettingBookings === false && refreshFromModalNeeded === true) {
      getBookings(
        token,
        tableData && tableData.currentPage != null ? tableData.currentPage : 1,
        'created',
        'descending',
        null
      );
    }
  }, [isGettingBookings]);

  useEffect(() => {
    if (
      dropdownOptions.filter((option) => option.key === 'tablesTitle').length === 0 &&
      shop &&
      shop.tables
    ) {
      dropdownOptions.push({
        key: 'tablesTitle',
        text: (
          <div>
            <strong>{i18n.t('tablesHeading')}</strong>
          </div>
        ),
        value: 'tablesTitle',
        disabled: true,
      });
      const tmpTables = sortBy(shop.tables, 'number');
      const tables = tmpTables.map((table) => ({
        key: table.description + table.number,
        text: `(${table.number}) ${table.description}`,
        value: table.number,
      }));
      Array.prototype.push.apply(dropdownOptions, tables);
    }
  }, [shop, dropdownOptions]);

  useEffect(() => {
    let timer;
    if (tableData) {
      if (
        tableData.bookings &&
        tableData.bookings.length === 0 &&
        tableData.isFiltering === false &&
        tableData.isSearching === false &&
        isBookingsAvailable === true &&
        isGettingBookings === false
      ) {
        getBookings(
          token,
          tableData.currentPage != null ? tableData.currentPage : 1,
          tableData.selectedColumn != null ? tableData.selectedColumn : 'created',
          tableData.sortDirection,
          tableData.selectedColumn,
          null
        );
      }
      if (!tableData.isSearching && isBookingsAvailable) {
        timer = setTimeout(() => {
          getBookings(
            token,
            tableData.currentPage != null ? tableData.currentPage : 1,
            tableData.selectedColumn != null ? tableData.selectedColumn : 'created',
            tableData.sortDirection,
            tableData.selectedColumn,
            null
          );
        }, 15000);
      }
    }
    if (tableData == null && !refreshRequired) {
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('bookingsLoadingError'),
        ok: true,
        onOk: () => {
          closeErrorModal();
        },
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
      setRefreshRequired(true);
      setIsGettingBookings(false);
    }
    if (
      shop &&
      shop.terminalReaders &&
      shop.terminalReaders.length > 0 &&
      stripeTerminal === null &&
      isTerminalReaderConnectionPossible === true &&
      user &&
      user.role !== 'employee'
    ) {
      createTerminal();
    }
    if (
      shop &&
      shop.terminalReaders &&
      shop.terminalReaders.length > 0 &&
      stripeTerminal !== null &&
      stripeTerminal.connectionStatus === 'not_connected' &&
      isTerminalReaderConnectionPossible === true &&
      isDiscoverTerminalBeingCalled === false &&
      user &&
      user.role !== 'employee'
    ) {
      setIsDiscoverTerminalBeingCalled(true);
      discoverTerminalReaders();
    }
    if (matches === false && showTableView === true) {
      setShowTableView(false);
      setShowGridView(false);
    }
    if (mobileMatches === false) {
      setShowGridView(true);
    }
    return () => {
      clearTimeout(timer);
    };
  });

  useEffect(() => {
    showDefaultOnDisplay();
  }, []);

  const showDefaultOnDisplay = () => {
    if (user && user.role === 'manager') {
      const displayPrinters = shop.printers
        ? shop.printers.filter((printer) => printer.hasCustomerDisplay === true)
        : [];
      displayPrinters.forEach((printer) => {
        const printerDevice = new Printer(printer);
        if (
          (printer.firstLine && printer.firstLine.length > 0) ||
          (printer.firstLine && printer.firstLine.length > 0)
        ) {
          printerDevice.display2Lines(printer.firstLine, printer.secondLine);
        } else {
          printerDevice.display2Lines('Herzlich Willkommen!', null);
        }
      });
    }
  };

  const showTerminalConnectionImpossibleError = (message) => {
    setIsTerminalReaderConnectionPossible(false);
    showToast({
      message: `${i18n.t('cardReaderError1')}\n ${
        `${i18n.t('newPrinterErrorTitle')}: ${message}` || ''
      }`,
      title: i18n.t('cardReader'),
      acknowledgable: true,
      id: 'TerminalConnectionError',
    });
    setIsTerminalReaderConnectionPossible(false);
  };

  const getConnectionToken = async () => {
    try {
      const res = await createTerminalReaderConnectionToken(token);
      if (res && res.success) {
        return res.secret;
      }
      showTerminalConnectionImpossibleError();
      return null;
    } catch (error) {
      showTerminalConnectionImpossibleError();
      return null;
    }
  };

  const onReconnect = (terminal) => {
    setPreferredTerminalToConnect(terminal);
    setIsDiscoverTerminalBeingCalled(false);
    setIsTerminalReaderConnectionPossible(true);
    setStripeTerminal(null);
  };

  const onUnexpectedReaderDisconnect = (e) => {
    if (e && e.error && e.error.message === 'The POS is no longer authenticated.') {
      console.log(e.error.message);
    } else {
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('cardReaderError6'),
        ok: false,
        onOk: null,
        yes: true,
        onYes: () => {
          setIsTerminalReaderConnectionPossible(true);
          setStripeTerminal(null);
          setTimeout(() => {
            closeErrorModal();
          }, 4000);
        },
        no: true,
        onNo: () => {
          setIsTerminalReaderConnectionPossible(false);
          closeErrorModal();
        },
      });
    }
  };

  const createTerminal = async () => {
    const StripeTerminal = await loadStripeTerminal();
    const terminal = StripeTerminal.create({
      onFetchConnectionToken: getConnectionToken,
      onUnexpectedReaderDisconnect,
    });
    setIsDiscoverTerminalBeingCalled(false);
    setStripeTerminal(terminal);
  };

  const discoverTerminalReaders = async () => {
    try {
      setIsDiscoveringTerminal(true);
      const discoverResult = await stripeTerminal.discoverReaders({
        location: shop.stripeLocationId,
      });
      setIsDiscoveringTerminal(false);
      if (discoverResult.error) {
        showTerminalConnectionImpossibleError(
          discoverResult.error ? discoverResult.error.message : ''
        );
      } else if (discoverResult.discoveredReaders.length === 0) {
        showTerminalConnectionImpossibleError(i18n.t('cardReaderError3'));
      } else {
        setDiscoveredTerminals(discoverResult.discoveredReaders);
        connectReader(discoverResult);
      }
    } catch (error) {
      showToast({
        error: true,
        message: `${i18n.t('cardReaderError4')}${error}`,
        title: i18n.t('newPrinterErrorTitle'),
        id: `CardReaderError-discoverReaders`,
      });
    } finally {
      setIsDiscoveringTerminal(false);
    }
  };

  const connectReader = async (discoverResult) => {
    setTerminalReaderModalOptions({
      ...terminalReaderModalOptions,
      isConnectingToTerminal: true,
    });
    if (stripeTerminal != null) {
      stripeTerminal.disconnectReader();
    }
    let selectedReader;
    if (preferredTerminalToConnect != null) {
      selectedReader = discoverResult.discoveredReaders.find(
        (reader) => reader.id === preferredTerminalToConnect.id
      );
    } else {
      selectedReader = discoverResult.discoveredReaders.find(
        (reader) => reader.status === 'online'
      );
    }
    try {
      const connectResult = await stripeTerminal.connectReader(selectedReader, {
        fail_if_in_use: true,
      });
      if (connectResult.error) {
        showTerminalConnectionImpossibleError(
          connectResult.error ? connectResult.error.message : ''
        );
        setTerminalReaderModalOptions({
          ...terminalReaderModalOptions,
          isConnectingToTerminal: false,
        });
      } else {
        setStripeTerminal(stripeTerminal);
        if (terminalReaderModalOptions.open === true) {
          setTerminalReaderModalOptions({
            open: true,
            terminals: discoverResult.discoveredReaders,
            connectedReader: stripeTerminal,
            isConnectingToTerminal: false,
          });
        }
      }
    } catch (error) {
      setTerminalReaderModalOptions({
        ...terminalReaderModalOptions,
        isConnectingToTerminal: false,
      });
      showToast({
        error: true,
        message: i18n.t('cardReaderError5'),
        title: i18n.t('newPrinterErrorTitle'),
        id: `CardReaderError-connectReader`,
      });
    }
  };

  const onErrorWarningModalOk = () => {
    setErrorWarningModalOptions({ open: false, message: '' });
    onClosePinModal();
  };

  useEffect(() => {
    if (localStorage.getItem('failedPrints')) {
      const failedPrints = JSON.parse(localStorage.getItem('failedPrints'));
      const newFailedPrints = failedPrints.filter((printjob) =>
        moment(printjob.created).isBetween(moment().subtract(12, 'hours'), moment())
      );
      localStorage.setItem('failedPrints', JSON.stringify(newFailedPrints));
    }

    const handleStorage = () => {
      if (localStorage.getItem('failedPrints')) {
        const failedPrints = JSON.parse(localStorage.getItem('failedPrints'));
        setFailedPrints([...failedPrints]);
      }
    };

    window.addEventListener('storage', handleStorage);
    return () => window.removeEventListener('storage', handleStorage);
  }, []);

  const getBookings = async (token, page, entity, direction, selectedColumn, isReset) => {
    if (localStorage.getItem('failedPrints')) {
      const failedPrints = JSON.parse(localStorage.getItem('failedPrints'));
      setFailedPrints([...failedPrints]);
    }
    setIsGettingBookings(true);
    setIsLoadingBookings(true);
    let bookingsTableData;
    if (
      tableData &&
      tableData.isFiltering &&
      (currentStatus.length > 0 ||
        currentPaymentType.length > 0 ||
        currentTypeType.length > 0 ||
        currentTable != null) &&
      isReset !== true
    ) {
      if (
        currentStatus === 'open' ||
        currentStatus === 'refund' ||
        currentStatus === 'closed' ||
        currentStatus === 'cancelled'
      ) {
        bookingsTableData =
          user.role === 'employee'
            ? await getBookingsStatusDataEmployee(token, currentStatus, page, direction)
            : await getBookingsStatusData(token, currentStatus, page, direction);
      }
      if (
        currentPaymentType === 'card' ||
        currentPaymentType === 'cash' ||
        currentPaymentType === 'later' ||
        currentPaymentType === 'request'
      ) {
        bookingsTableData =
          user.role === 'employee'
            ? await getBookingsPaymentTypeDataEmployee(token, currentPaymentType, page, direction)
            : await getBookingsPaymentTypeData(token, currentPaymentType, page, direction);
      }
      if (currentTypeType === 'terminal' || currentTypeType === 'qr') {
        bookingsTableData =
          user.role === 'employee'
            ? await getBookingsTypeTypeDataEmployee(token, currentTypeType, page, direction)
            : await getBookingsTypeTypeData(token, currentTypeType, page, direction);
      }
      if (currentTable != null && typeof currentTable === 'number') {
        bookingsTableData =
          user.role === 'employee'
            ? await getBookingsByTableDataEmployee(token, currentTable, page, direction)
            : await getBookingsByTableData(token, currentTable, page, direction);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (user.role === 'employee') {
        bookingsTableData = await getBookingsDataEmployee(
          token,
          page,
          entity,
          direction,
          selectedColumn
        );
      } else {
        bookingsTableData = await getBookingsData(token, page, entity, direction, selectedColumn);
        if (bookingsTableData && bookingsTableData.rooms && bookingsTableData.rooms.length > 0) {
          if (
            selectedRoomIndex === 0 &&
            bookingsTableData.rooms.filter(
              (room, idx) =>
                idx !== selectedRoomIndex &&
                room.tables.filter((table) => table.bookings && table.bookings.length > 0).length >
                  0
            ).length > 0
          ) {
            setOtherRoomWithNewBookings(true);
          } else {
            setOtherRoomWithNewBookings(false);
          }
          const roomsDropDownItems = bookingsTableData.rooms.map((room, index) => ({
            key: room.name + index,
            text: <p>{room.name}</p>,
            value: room.name,
          }));
          setRoomsDropdownOptions(roomsDropDownItems);
          if (selectedRoomIndex !== 0 && bookingsTableData.rooms[selectedRoomIndex]) {
            setSelectedRoomIndex(selectedRoomIndex);
            setSelectedRoomName(bookingsTableData.rooms[selectedRoomIndex].name);
            if (
              bookingsTableData.rooms.filter(
                (room, idx) =>
                  selectedRoomIndex !== idx &&
                  room.tables.filter((table) => table.bookings && table.bookings.length > 0)
                    .length > 0
              ).length > 0
            ) {
              setOtherRoomWithNewBookings(true);
            } else {
              setOtherRoomWithNewBookings(false);
            }
          } else {
            setSelectedRoomIndex(0);
            setSelectedRoomName(bookingsTableData.rooms[0].name);
          }
        }
      }
    }
    if (
      bookingsTableData &&
      bookingsTableData.bookings &&
      bookingsTableData.bookings.length === 0
    ) {
      setIsBookingAvailable(false);
    }
    setTableData(bookingsTableData);
    setSearchValue('');
    setIsLoadingBookings(false);
  };

  const searchInBookings = async (token, searchTerm, page) => {
    const searchData = await getSearchData(token, searchTerm, page);
    setIsSearchLoading(false);
    setTableData(searchData);
  };

  const searchInBookingsEmployee = async (token, searchTerm, page) => {
    const searchData = await getSearchDataEmployee(token, searchTerm, page);
    setIsSearchLoading(false);
    setTableData(searchData);
  };

  const getBookingsStatus = async (token, status, page, direction) => {
    const statusData = await getBookingsStatusData(token, status, page, direction);
    setTableData(statusData);
  };

  const getBookingsStatusEmployee = async (token, status, page, direction) => {
    const statusData = await getBookingsStatusDataEmployee(token, status, page, direction);
    setTableData(statusData);
  };

  const getBookingsPaymentType = async (token, paymentType, page, direction) => {
    const paymentTypeData = await getBookingsPaymentTypeData(token, paymentType, page, direction);
    setTableData(paymentTypeData);
  };

  const getBookingsPaymentTypeEmployee = async (token, paymentType, page, direction) => {
    const paymentTypeData = await getBookingsPaymentTypeDataEmployee(
      token,
      paymentType,
      page,
      direction
    );
    setTableData(paymentTypeData);
  };

  const getBookingsTypeType = async (token, paymentType, page, direction) => {
    const paymentTypeData = await getBookingsTypeTypeData(token, paymentType, page, direction);
    setTableData(paymentTypeData);
  };

  const getBookingsTypeTypeEmployee = async (token, paymentType, page, direction) => {
    const paymentTypeData = await getBookingsTypeTypeDataEmployee(
      token,
      paymentType,
      page,
      direction
    );
    setTableData(paymentTypeData);
  };

  const getBookingsByTable = async (token, paymentType, page, direction) => {
    const paymentTypeData = await getBookingsByTableData(token, paymentType, page, direction);
    setTableData(paymentTypeData);
  };

  const getBookingsByTableEmployee = async (token, paymentType, page, direction) => {
    const paymentTypeData = await getBookingsByTableDataEmployee(
      token,
      paymentType,
      page,
      direction
    );
    setTableData(paymentTypeData);
  };

  const showPrinterError = (printerDevice) => {
    showToast({
      error: true,
      message: `${i18n.t('printerToastError1')} "${printerDevice.printer.description}" ${i18n.t(
        'printerToastError2'
      )}`,
      title: i18n.t('newPrinterErrorTitle'),
      id: `printBookingsScreenError${printerDevice.printer.description}`,
    });
  };

  const onCloseAll = async (tableId) => {
    try {
      setWarningModalOptions({
        open: true,
        title: i18n.t('closeAllOpenOrders'),
        message: i18n.t('closeAllOpenOrdersWarning'),
        ok: false,
        onOk: null,
        yes: true,
        onYes: async () => {
          if (shop.isPinRequired === true && user.role !== 'owner') {
            setPinOptions({
              open: true,
              onSendPin: async (pin) => {
                try {
                  const res = await closeAllBookingsPin(tableId, token, pin);
                  if (res && res.success) {
                    setRefreshFromModalNeeded(true);
                    setIsGettingBookings(false);
                    setTableData({ bookings: [] });
                    setBookingsListModalOptions({ open: false, bookings: [], table: null });
                  } else {
                    setErrorModalOptions({
                      open: true,
                      title: i18n.t('error'),
                      message: i18n.t('closeAllOpenOrdersError1'),
                      ok: true,
                      onOk: () => closeErrorModal(),
                      yes: false,
                      onYes: null,
                      no: false,
                      onNo: null,
                    });
                  }
                  onClosePinModal();
                  closeWarningModal();
                } catch (error) {
                  setErrorModalOptions({
                    open: true,
                    title: i18n.t('error'),
                    message: i18n.t('closeAllOpenOrdersError1'),
                    ok: true,
                    onOk: () => closeErrorModal(),
                    yes: false,
                    onYes: null,
                    no: false,
                    onNo: null,
                  });
                  onClosePinModal();
                  closeWarningModal();
                }
              },
            });
          } else {
            const res = await closeAllBookings(tableId, token);
            closeWarningModal();
            if (res && res.success) {
              setRefreshFromModalNeeded(true);
              setIsGettingBookings(false);
              setTableData({ bookings: [] });
              setBookingsListModalOptions({ open: false, bookings: [], table: null });
            } else {
              setErrorModalOptions({
                open: true,
                title: i18n.t('error'),
                message: i18n.t('closeAllOpenOrdersError2'),
                ok: true,
                onOk: () => closeErrorModal(),
                yes: false,
                onYes: null,
                no: false,
                onNo: null,
              });
            }
          }
        },
        no: true,
        onNo: () => closeWarningModal(),
      });
    } catch (error) {
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('closeAllOpenOrdersError2'),
        ok: true,
        onOk: () => closeErrorModal(),
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
      closeWarningModal();
    }
  };

  const onCloseDailySummaryModal = () => {
    setDailySummaryModalOptions({ open: false, role: '', pin: '' });
  };

  const onCloseBookingsMergeModal = (refreshNeeded) => {
    setBookingsMergeModalOptions({ open: false });
    if (refreshNeeded) {
      setRefreshFromModalNeeded(true);
      setIsGettingBookings(false);
      setTableData({ bookings: [] });
    }
  };

  const onCloseTerminalReaderModal = () => {
    setTerminalReaderModalOptions({
      open: false,
      terminals: [],
      connectedReader: null,
      isConnectingToTerminal: false,
    });
  };

  const onCloseBookingsCreateModal = (refreshNeeded) => {
    setBookingsCreateModalOptions({
      open: false,
      terminal: null,
      table: null,
      bookingId: null,
      isDiscoveringTerminal,
    });
    if (refreshNeeded) {
      setRefreshFromModalNeeded(true);
      setIsGettingBookings(false);
      setTableData({ bookings: [] });
      setBookingsCreateModalOptions({
        open: false,
        terminal: null,
        table: null,
        bookingId: null,
        isDiscoveringTerminal,
      });
    }
    showDefaultOnDisplay();
  };

  const onBackgroundRefresh = () => {
    setRefreshFromModalNeeded(true);
    setIsGettingBookings(false);
    setTableData({ bookings: [] });
    setBookingsCreateModalOptions({
      ...bookingsCreateModalOptions,
      table: null,
      isDiscoveringTerminal,
    });
  };

  const onDropDownChange = (value) => {
    setDropdownOptions(value);
  };

  const clearDropdown = () => {
    setDropdownOptions('');
  };

  const onCloseModal = (refreshNeeded) => {
    setModalOptions({ booking: null, open: false, closeEditMode: false });
    if (refreshNeeded) {
      setRefreshFromModalNeeded(true);
      setIsGettingBookings(false);
      setTableData({ bookings: [] });
    }
  };

  const onClosePinModal = () => {
    setPinOptions({ open: false, onSendPin: null });
    closeWarningModal();
  };

  const onRefreshShop = async () => {
    try {
      const res = await getMe(token);
      if (res && res.success) {
        dispatch({
          type: 'update',
          shop: res.me.shop,
          config: res.me.config,
          user: res.me.user,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const openCashDrawer = () => {
    if (
      shop.printers &&
      shop.printers.length > 0 &&
      shop.printers.find((printer) => printer.isMain === true)
    ) {
      const printerDevice = new Printer(shop.printers.find((printer) => printer.isMain === true));
      printerDevice.epos.onerror = () => console.error('Error opening cash drawer.');
      printerDevice.openDrawer();
    }
  };

  const onRefund = async (booking) => {
    setWarningModalOptions({
      open: true,
      title: i18n.t('warning'),
      message: i18n.t('bookingsRefundWarning'),
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        if (shop.isPinRequired === true && user.role !== 'owner') {
          setPinOptions({
            open: true,
            onSendPin: async (pin) => {
              let res;
              if (
                booking.paymentType === 'cash' ||
                (booking.paymentType === 'card' &&
                  (booking.paymentIntentId == null || booking.paymentIntentId === ''))
              ) {
                if (booking.paymentType === 'cash') {
                  openCashDrawer();
                }
                res = await refundCashPin(token, booking._id, pin);
              } else if (booking.paymentType === 'card') {
                res = await refundCardPin(token, booking._id, pin);
              } else if (booking.paymentType === 'later') {
                res = await refundLaterPin(token, booking._id, pin);
              }
              if (res && res.success) {
                setModalOptions({ open: true, booking: res.booking, closeEditMode: false });
                closeWarningModal();
                onClosePinModal();
                await getBookings(token, 1, 'created', 'descending', null);
              } else {
                closeWarningModal();
                setErrorModalOptions({
                  open: true,
                  title: i18n.t('error'),
                  message: i18n.t('refundError1'),
                  ok: true,
                  onOk: () => closeErrorModal(),
                  yes: false,
                  onYes: null,
                  no: false,
                  onNo: null,
                });
                onClosePinModal();
              }
            },
          });
        } else {
          let res;
          if (
            booking.paymentType === 'cash' ||
            (booking.paymentType === 'card' &&
              (booking.paymentIntentId == null || booking.paymentIntentId === ''))
          ) {
            if (booking.paymentType === 'cash') {
              openCashDrawer();
            }
            res = await refundCash(token, booking._id);
          } else if (booking.paymentType === 'card') {
            res = await refundCard(token, booking._id);
          } else if (booking.paymentType === 'later') {
            res = await refundLater(token, booking._id);
          }
          if (res && res.success) {
            setModalOptions({ open: true, booking: res.booking, closeEditMode: false });
            closeWarningModal();
            await getBookings(token, 1, 'created', 'descending', null);
          } else {
            closeWarningModal();
            setErrorModalOptions({
              open: true,
              title: i18n.t('error'),
              message: i18n.t('refundError1'),
              ok: true,
              onOk: () => closeErrorModal(),
              yes: false,
              onYes: null,
              no: false,
              onNo: null,
            });
          }
        }
      },
      no: true,
      onNo: () => closeWarningModal(),
    });
  };

  const onSaveEditing = async (booking) => {
    try {
      setWarningModalOptions({
        open: true,
        title: i18n.t('bookingEditBookingTitle'),
        message: i18n.t('bookingEditBookingWarning'),
        ok: false,
        onOk: null,
        yes: true,
        onYes: async () => {
          if (shop.isPinRequired === true && user.role !== 'owner') {
            setPinOptions({
              open: true,
              onSendPin: async (pin) => {
                let res;
                if (
                  booking.paymentType === 'cash' ||
                  (booking.paymentType === 'card' &&
                    (booking.paymentIntentId == null || booking.paymentIntentId === ''))
                ) {
                  if (booking.paymentType === 'cash') {
                    openCashDrawer();
                  }
                  res = await editCashBookingPin(token, booking._id, booking.bookedItems, pin);
                } else if (booking.paymentType === 'card') {
                  res = await editCardBookingPin(token, booking._id, booking.bookedItems, pin);
                } else if (booking.paymentType === 'later') {
                  res = await editLaterBookingPin(token, booking._id, booking.bookedItems, pin);
                }
                if (res && res.success) {
                  setModalOptions({ open: true, booking: res.booking, closeEditMode: true });
                  closeWarningModal();
                  setModalOptions({ open: true, booking: res.booking, closeEditMode: false });
                  onClosePinModal();
                  await getBookings(token, 1, 'created', 'descending', null);
                } else {
                  closeWarningModal();
                  setErrorModalOptions({
                    open: true,
                    title: i18n.t('error'),
                    message: i18n.t('editingError1'),
                    ok: true,
                    onOk: () => closeErrorModal(),
                    yes: false,
                    onYes: null,
                    no: false,
                    onNo: null,
                  });
                  onClosePinModal();
                }
              },
            });
          } else {
            let res;
            if (
              booking.paymentType === 'cash' ||
              (booking.paymentType === 'card' &&
                (booking.paymentIntentId == null || booking.paymentIntentId === ''))
            ) {
              if (booking.paymentType === 'cash') {
                openCashDrawer();
              }
              res = await editCashBooking(token, booking._id, booking.bookedItems);
            } else if (booking.paymentType === 'card') {
              res = await editCardBooking(token, booking._id, booking.bookedItems);
            } else if (booking.paymentType === 'later') {
              res = await editLaterBooking(token, booking._id, booking.bookedItems);
            }
            if (res && res.success) {
              setModalOptions({ open: true, booking: res.booking, closeEditMode: true });
              closeWarningModal();
              setModalOptions({ open: true, booking: res.booking, closeEditMode: false });
              await getBookings(token, 1, 'created', 'descending', null);
            } else {
              closeWarningModal();
              setErrorModalOptions({
                open: true,
                title: i18n.t('error'),
                message: i18n.t('editingError1'),
                ok: true,
                onOk: () => closeErrorModal(),
                yes: false,
                onYes: null,
                no: false,
                onNo: null,
              });
            }
          }
        },
        no: true,
        onNo: () => closeWarningModal(),
      });
    } catch (error) {
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('bookingEditError'),
        ok: true,
        onOk: () => closeErrorModal(),
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
      closeWarningModal();
    }
  };

  const onSaveSplitting = async (bookingId, oldBookedItems, newBookedItems) => {
    try {
      setWarningModalOptions({
        open: true,
        title: i18n.t('splitOrder'),
        message: i18n.t('splitOrderWarning'),
        ok: false,
        onOk: null,
        yes: true,
        onYes: async () => {
          if (shop.isPinRequired === true && user.role === 'manager') {
            await onSaveSplitPin(bookingId, oldBookedItems, newBookedItems);
          } else {
            await onSaveSplit(bookingId, oldBookedItems, newBookedItems);
          }
        },
        no: true,
        onNo: () => closeWarningModal(),
      });
    } catch (error) {
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: `${i18n.t('bookingSplitError')} ${error}`,
        ok: true,
        onOk: () => closeErrorModal(),
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
      closeWarningModal();
    }
  };

  const onSaveSplitPin = async (bookingId, oldBookedItems, newBookedItems) => {
    setPinOptions({
      open: true,
      onSendPin: async (pin) => {
        const res = await splitBookingPin(bookingId, oldBookedItems, newBookedItems, token, pin);
        if (res && res.success) {
          setModalOptions({ open: false, booking: null, closeEditMode: true });
          closeWarningModal();
          onClosePinModal();
          await getBookings(token, 1, 'created', 'descending', null);
        } else {
          closeWarningModal();
          setErrorModalOptions({
            open: true,
            title: i18n.t('error'),
            message: i18n.t('bookingSplitError'),
            ok: true,
            onOk: () => closeErrorModal(),
            yes: false,
            onYes: null,
            no: false,
            onNo: null,
          });
          onClosePinModal();
        }
      },
    });
  };

  const onSaveSplit = async (bookingId, oldBookedItems, newBookedItems) => {
    const res = await splitBooking(bookingId, oldBookedItems, newBookedItems, token);
    if (res && res.success) {
      setModalOptions({ open: false, booking: null, closeEditMode: true });
      closeWarningModal();
      await getBookings(token, 1, 'created', 'descending', null);
    } else {
      closeWarningModal();
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('bookingSplitError'),
        ok: true,
        onOk: () => closeErrorModal(),
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
    }
  };

  const onAcceptingRequest = async (bookingId) => {
    try {
      setWarningModalOptions({
        open: true,
        title: i18n.t('qrRequest'),
        message: i18n.t('qrRequestWarning'),
        ok: false,
        onOk: null,
        yes: true,
        onYes: async () => {
          if (shop.isPinRequired === true && user.role === 'manager') {
            await onAcceptRequestPin(bookingId);
          } else {
            await onAcceptRequest(bookingId);
          }
        },
        no: true,
        onNo: () => closeWarningModal(),
      });
    } catch (error) {
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('qrRequestError1'),
        ok: true,
        onOk: () => closeErrorModal(),
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
      closeWarningModal();
    }
  };

  const onAcceptRequestPin = async (bookingId) => {
    setPinOptions({
      open: true,
      onSendPin: async (pin) => {
        const res = await acceptQRRequestPin(bookingId, token, pin);
        if (res && res.success) {
          setModalOptions({ open: false, booking: null, closeEditMode: true });
          closeWarningModal();
          onClosePinModal();
          await getBookings(token, 1, 'created', 'descending', null);
        } else {
          closeWarningModal();
          setErrorModalOptions({
            open: true,
            title: i18n.t('error'),
            message: i18n.t('qrRequestError1'),
            ok: true,
            onOk: () => closeErrorModal(),
            yes: false,
            onYes: null,
            no: false,
            onNo: null,
          });
          onClosePinModal();
        }
      },
    });
  };

  const onAcceptRequest = async (bookingId) => {
    const res = await acceptQRRequest(bookingId, token);
    if (res && res.success) {
      setModalOptions({ open: false, booking: null, closeEditMode: true });
      closeWarningModal();
      await getBookings(token, 1, 'created', 'descending', null);
    } else {
      closeWarningModal();
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('qrRequestError1'),
        ok: true,
        onOk: () => closeErrorModal(),
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
    }
  };

  const onDecliningRequest = async (bookingId) => {
    try {
      setWarningModalOptions({
        open: true,
        title: i18n.t('qrRequest'),
        message: i18n.t('qrRequestWarning2'),
        ok: false,
        onOk: null,
        yes: true,
        onYes: async () => {
          if (shop.isPinRequired === true && user.role === 'manager') {
            await onDeclineRequestPin(bookingId);
          } else {
            await onDeclineRequest(bookingId);
          }
        },
        no: true,
        onNo: () => closeWarningModal(),
      });
    } catch (error) {
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('qrRequestError2'),
        ok: true,
        onOk: () => closeErrorModal(),
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
      closeWarningModal();
    }
  };

  const onDeclineRequestPin = async (bookingId) => {
    setPinOptions({
      open: true,
      onSendPin: async (pin) => {
        const res = await declineQRRequestPin(bookingId, token, pin);
        if (res && res.success) {
          setModalOptions({ open: false, booking: null, closeEditMode: true });
          closeWarningModal();
          onClosePinModal();
          await getBookings(token, 1, 'created', 'descending', null);
        } else {
          closeWarningModal();
          setErrorModalOptions({
            open: true,
            title: i18n.t('error'),
            message: i18n.t('qrRequestError2'),
            ok: true,
            onOk: () => closeErrorModal(),
            yes: false,
            onYes: null,
            no: false,
            onNo: null,
          });
          onClosePinModal();
        }
      },
    });
  };

  const onDeclineRequest = async (bookingId) => {
    const res = await declineQRRequest(bookingId, token);
    if (res && res.success) {
      setModalOptions({ open: false, booking: null, closeEditMode: true });
      closeWarningModal();
      await getBookings(token, 1, 'created', 'descending', null);
    } else {
      closeWarningModal();
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('qrRequestError2'),
        ok: true,
        onOk: () => closeErrorModal(),
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
    }
  };

  const onSaveEditingTable = async (bookingId, tableNumber) => {
    try {
      setWarningModalOptions({
        open: true,
        title: i18n.t('changeTable'),
        message: i18n.t('changeTableWarning'),
        ok: false,
        onOk: null,
        yes: true,
        onYes: async () => {
          let res;
          if (shop.isPinRequired === true && user.role !== 'owner') {
            await onSaveEditTablePin(bookingId, tableNumber);
          } else {
            await onSaveEditTable(bookingId, tableNumber);
          }
        },
        no: true,
        onNo: () => closeWarningModal(),
      });
    } catch (error) {
      closeWarningModal();
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: `${i18n.t('tablesUpdateError')} ${error}`,
        ok: true,
        onOk: () => closeErrorModal(),
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
    }
  };

  const onSaveEditTable = async (bookingId, tableNumber) => {
    const res = await updateTableOfBooking(bookingId, tableNumber, token);
    if (res && res.success) {
      setModalOptions({ open: false, booking: null, closeEditMode: true });
      closeWarningModal();
      await getBookings(token, 1, 'created', 'descending', null);
    } else {
      closeWarningModal();
      setErrorModalOptions({
        open: true,
        title: i18n.t('error'),
        message: i18n.t('tablesUpdateError'),
        ok: true,
        onOk: () => closeErrorModal(),
        yes: false,
        onYes: null,
        no: false,
        onNo: null,
      });
    }
  };

  const onSaveEditTablePin = async (bookingId, tableNumber) => {
    setPinOptions({
      open: true,
      onSendPin: async (pin) => {
        const res = await updateTableOfBookingPin(bookingId, tableNumber, token, pin);
        if (res && res.success) {
          setModalOptions({ open: false, booking: null, closeEditMode: true });
          closeWarningModal();
          onClosePinModal();
          await getBookings(token, 1, 'created', 'descending', null);
        } else {
          closeWarningModal();
          setErrorModalOptions({
            open: true,
            title: i18n.t('error'),
            message: i18n.t('tablesUpdateError'),
            ok: true,
            onOk: () => closeErrorModal(),
            yes: false,
            onYes: null,
            no: false,
            onNo: null,
          });
          onClosePinModal();
        }
      },
    });
  };

  const onChangeStatus = async (bookingId, status) => {
    const statusText =
      status === 'open'
        ? i18n.t('bookingModalChangeStatusOpen').toUpperCase()
        : status === 'closed'
        ? i18n.t('bookingModalChangeStatusDone').toUpperCase()
        : i18n.t('bookingsRefunded').toUpperCase();
    setWarningModalOptions({
      open: true,
      title: i18n.t('warning'),
      message:
        status === 'closed'
          ? `${i18n.t('bookingStatusWarning1')} ${statusText}${i18n.t('bookingStatusWarning2')}`
          : `${i18n.t('bookingStatusWarning1')} ${statusText}${i18n.t('bookingStatusWarning2')}`,
      ok: false,
      onOk: null,
      yes: true,
      onYes: async () => {
        if (
          shop.isPinRequired === true &&
          user.role !== 'owner' &&
          user.role !== 'employee' &&
          (user.isOrdering === true || user.isPaying === true)
        ) {
          setPinOptions({
            open: true,
            onSendPin: async (pin) => {
              const res = await changeStatusPin(token, status, bookingId, pin);
              if (res.success) {
                setModalOptions({ open: true, booking: res.booking, closeEditMode: false });
                closeWarningModal();
                onClosePinModal();
                await getBookings(token, 1, 'created', 'descending', null);
              } else {
                closeWarningModal();
                setErrorModalOptions({
                  open: true,
                  title: i18n.t('error'),
                  message: i18n.t('statusChangeError'),
                  ok: true,
                  onOk: () => closeErrorModal(),
                  yes: false,
                  onYes: null,
                  no: false,
                  onNo: null,
                });
                onClosePinModal();
              }
            },
          });
        } else {
          const res = await changeStatus(token, status, bookingId);
          if (res.success) {
            setModalOptions({ open: true, booking: res.booking, closeEditMode: false });
            closeWarningModal();
            await getBookings(token, 1, 'created', 'descending', null);
          } else {
            closeWarningModal();
            setErrorModalOptions({
              open: true,
              title: i18n.t('error'),
              message: i18n.t('statusChangeError'),
              ok: true,
              onOk: () => closeErrorModal(),
              yes: false,
              onYes: null,
              no: false,
              onNo: null,
            });
          }
        }
      },
      no: true,
      onNo: () => closeWarningModal(),
    });
  };

  const onPayLater = async (tableId, bookingId) => {
    setBookingsCreateModalOptions({
      open: true,
      terminal:
        stripeTerminal && stripeTerminal.connectionStatus === 'connected' ? stripeTerminal : null,
      table: tableId,
      bookingId,
      isDiscoveringTerminal,
    });
    setModalOptions({ open: false, booking: null, closeEditMode: false });
  };

  const closeWarningModal = () => {
    setWarningModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
      amount: null,
    });
  };

  const closeErrorModal = () => {
    setErrorModalOptions({
      open: false,
      title: '',
      message: '',
      ok: false,
      onOk: null,
      yes: false,
      onYes: null,
      no: false,
      onNo: null,
    });
  };

  const handleSort = async (selectedColumn) => {
    if (!tableData.isFiltering && !tableData.isSearching) {
      if (tableData.selectedColumn !== selectedColumn) {
        await getBookings(
          token,
          tableData.currentPage,
          selectedColumn,
          'ascending',
          selectedColumn
        );
        return;
      }
      const sortDirection = tableData.sortDirection === 'ascending' ? 'descending' : 'ascending';
      await getBookings(
        token,
        tableData.currentPage,
        selectedColumn,
        sortDirection,
        selectedColumn
      );
    } else {
      const isFiltering = tableData.isFiltering ? tableData.isFiltering : false;
      const isSearching = tableData.isSearching ? tableData.isSearching : false;
      if (tableData.selectedColumn !== selectedColumn) {
        setTableData({
          bookings: sortBy(tableData.bookings, [selectedColumn]),
          selectedColumn,
          sortDirection: 'ascending',
          totalPages: tableData.totalPages,
          currentPage: tableData.currentPage,
          isFiltering,
          isSearching,
          searchTerm: tableData.searchTerm,
          rooms: [],
        });
        return;
      }
      setTableData({
        bookings: tableData && tableData.bookings ? tableData.bookings.slice().reverse() : [],
        selectedColumn,
        sortDirection: tableData.sortDirection === 'ascending' ? 'descending' : 'ascending',
        totalPages: tableData.totalPages,
        currentPage: tableData.currentPage,
        isFiltering,
        isSearching,
        searchTerm: tableData.searchTerm,
        rooms: [],
      });
    }
  };

  const handlePageChange = async (_, { activePage }) => {
    if (tableData.isSearching) {
      await searchInBookings(token, tableData.searchTerm, activePage);
    } else {
      await getBookings(token, activePage, 'created', 'descending', null);
    }
  };

  const handleFilterChange = async (value) => {
    setSearchValue('');
    if (value) {
      if (value === 'open' || value === 'closed' || value === 'refund' || value === 'cancelled') {
        setCurrentPaymentType('');
        setCurrentTypeType('');
        setCurrentTable(null);
        setCurrentStatus(value);
        // eslint-disable-next-line no-unused-expressions
        user.role === 'employee'
          ? await getBookingsStatusEmployee(token, value, 1, tableData.sortDirection)
          : await getBookingsStatus(token, value, 1, tableData.sortDirection);
      }
      if (
        value === 'card' ||
        value === 'cash' ||
        value === 'later' ||
        value === 'request' ||
        value === 'unpaid'
      ) {
        setCurrentStatus('');
        setCurrentTypeType('');
        setCurrentTable(null);
        setCurrentPaymentType(value);
        // eslint-disable-next-line no-unused-expressions
        user.role === 'employee'
          ? await getBookingsPaymentTypeEmployee(token, value, 1, tableData.sortDirection)
          : await getBookingsPaymentType(token, value, 1, tableData.sortDirection);
      }
      if (value === 'terminal' || value === 'qr') {
        setCurrentStatus('');
        setCurrentPaymentType('');
        setCurrentTable(null);
        setCurrentTypeType(value);
        // eslint-disable-next-line no-unused-expressions
        user.role === 'employee'
          ? await getBookingsTypeTypeEmployee(token, value, 1, tableData.sortDirection)
          : await getBookingsTypeType(token, value, 1, tableData.sortDirection);
      }
      if (value != null && typeof value === 'number') {
        setCurrentStatus('');
        setCurrentPaymentType('');
        setCurrentTypeType('');
        setCurrentTable(value);
        // eslint-disable-next-line no-unused-expressions
        user.role === 'employee'
          ? await getBookingsByTableEmployee(token, value, 1, tableData.sortDirection)
          : await getBookingsByTable(token, value, 1, tableData.sortDirection);
      }
    } else {
      setCurrentStatus('');
      setCurrentPaymentType('');
      setCurrentTypeType('');
      setCurrentTable(null);
      await getBookings(token, 1, 'created', 'descending', null, true);
    }
  };

  const handleSearch = async (searchTerm) => {
    setSearchValue(searchTerm);
    clearDropdown();
    if (searchTerm.length > 0) {
      setIsSearchLoading(true);
      // eslint-disable-next-line no-unused-expressions
      user.role === 'employee'
        ? await searchInBookingsEmployee(token, searchTerm, 1)
        : await searchInBookings(token, searchTerm, 1);
    }
    if (searchTerm.length === 0) {
      await getBookings(token, 1, 'created', 'descending', null);
    }
  };

  const countElement = (bookedItems, index) => {
    const cleanedUpBookedItems = bookedItems.map((item) => {
      const newItem = {
        itemTitle: item.itemTitle,
        categoryName: item.categoryName,
        specialSelections: item.specialSelections.map((special) => ({
          specialSelectionId: special.specialSelectionId,
          specialSelectionTitle: special.specialSelectionTitle,
          specialSelectionPrice: special.specialSelectionPrice,
        })),
        options:
          item.options && item.options.length > 0
            ? item.options.map((option) => ({ name: option.name, price: option.price }))
            : null,
        amount: item.amount,
        taxRate: item.taxRate,
        isRefund: item.isRefund,
      };
      if (item.discount)
        newItem.discount = { amount: item.discount.amount, percentage: item.discount.percentage };
      if (item.notes) newItem.notes = item.notes;
      return newItem;
    });
    return countBy(cleanedUpBookedItems, (element) => isEqual(element, cleanedUpBookedItems[index]))
      .true;
  };

  const parseTableView = () => {
    const backgroundColor = (item) => {
      if (item.bookings && item.bookings.length > 0) {
        const unpaidBookings = item.bookings.filter(
          (booking) => booking.paid === false && booking.isRequest === false
        ).length;
        const requestBookings = item.bookings.filter((booking) => booking.isRequest === true)
          .length;
        const openBookings = item.bookings.filter(
          (booking) => booking.paid === true && booking.status === 'open'
        ).length;
        if (unpaidBookings > 0 && requestBookings === 0 && openBookings === 0) return 'firebrick';
        if (unpaidBookings > 0 && requestBookings > 0 && openBookings === 0)
          return 'linear-gradient(90deg, firebrick 25%, #77A1D3 75%)';
        if (unpaidBookings > 0 && requestBookings === 0 && openBookings > 0)
          return 'linear-gradient(90deg, firebrick 25%, coral 75%)';
        if (unpaidBookings > 0 && requestBookings > 0 && openBookings > 0)
          return 'linear-gradient(90deg, firebrick 20%, coral 40%, coral 60%, #77A1D3 80%)';
        if (unpaidBookings === 0 && requestBookings > 0 && openBookings === 0) return '#77A1D3';
        if (unpaidBookings === 0 && requestBookings > 0 && openBookings > 0)
          return 'linear-gradient(90deg, coral 25%, #77A1D3 80%)';
        if (unpaidBookings === 0 && requestBookings === 0 && openBookings > 0) return 'coral';
      }
      return '#7268cd';
    };
    let layout = [];
    if (tableData.rooms && tableData.rooms.length > 0 && selectedRoomIndex !== null) {
      layout = tableData.rooms[selectedRoomIndex].tables.map((item, index) => (
        <div
          key={item.number}
          className="tables-grid-item"
          style={{
            borderBottomRightRadius: '10px',
            cursor: 'pointer',
            WebkitUserSelect: 'none',
            userSelect: 'none',
            background: backgroundColor(item),
          }}
          data-grid={{
            x: item.x,
            y: item.y,
            w: item.w,
            h: item.h,
            minH: item.minH,
            maxW: item.maxW,
            maxH: item.maxH,
            minW: item.minW,
            isBounded: true,
            static: true,
            verticalCompact: true,
          }}
          onClick={() => {
            if (isLoadingBookings === false) {
              if (item.bookings && item.bookings.length === 1) {
                const bookingTableOverview = item.bookings[0];
                if (
                  (bookingTableOverview.paid === true && bookingTableOverview.status === 'open') ||
                  (bookingTableOverview.paid === false && bookingTableOverview.isRequest === true)
                ) {
                  setModalOptions({
                    open: true,
                    booking: bookingTableOverview,
                    closeEditMode: false,
                  });
                }
                if (
                  bookingTableOverview.paid === false &&
                  bookingTableOverview.isRequest === false
                ) {
                  setBookingsCreateModalOptions({
                    open: true,
                    terminal:
                      stripeTerminal && stripeTerminal.connectionStatus === 'connected'
                        ? stripeTerminal
                        : null,
                    table: item.tableId,
                    bookingId: bookingTableOverview.bookingId,
                    isDiscoveringTerminal,
                  });
                }
              }
              if (item.bookings && item.bookings.length > 1) {
                const unpaidBookings = item.bookings.filter(
                  (booking) => booking.paid === false && booking.isRequest === false
                ).length;
                const requestBookings = item.bookings.filter(
                  (booking) => booking.isRequest === true
                ).length;
                const openBookings = item.bookings.filter(
                  (booking) => booking.paid === true && booking.status === 'open'
                ).length;
                if (unpaidBookings > 0 && requestBookings === 0 && openBookings === 0) {
                  setBookingsCreateModalOptions({
                    open: true,
                    terminal:
                      stripeTerminal && stripeTerminal.connectionStatus === 'connected'
                        ? stripeTerminal
                        : null,
                    table: item.tableId,
                    bookingId: null,
                    isDiscoveringTerminal,
                  });
                } else {
                  setBookingsListModalOptions({
                    open: true,
                    table: item.number,
                    bookings: item.bookings,
                    withPayment: unpaidBookings > 0,
                  });
                }
              }
              if (item.bookings === null || item.bookings.length === 0) {
                setBookingsCreateModalOptions({
                  open: true,
                  terminal:
                    stripeTerminal && stripeTerminal.connectionStatus === 'connected'
                      ? stripeTerminal
                      : null,
                  table: item.tableId,
                  bookingId: null,
                  isDiscoveringTerminal,
                });
              }
            }
          }}
        >
          {item.number}
          {item.bookings && item.bookings.length > 1 && (
            <span
              style={{
                position: 'absolute',
                top: -8,
                left: -8,
                fontSize: 13,
                backgroundColor: '#e70909',
                height: 20,
                width: 20,
                borderRadius: 10,
                fontWeight: 500,
                color: '#eee',
              }}
            >
              {item.bookings.length}
            </span>
          )}
        </div>
      ));
    }
    return (
      <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          {otherRoomWithNewBookings === true && (
            <div
              style={{
                position: 'absolute',
                height: 16,
                width: 16,
                backgroundColor: '#e70909',
                border: '1px solid #e70909',
                borderRadius: 8,
                marginTop: -40,
                zIndex: 1002,
                marginLeft: 180,
              }}
            />
          )}
          <Dropdown
            selection
            placeholder="Raum wählen"
            options={roomsDropdownOptions}
            value={selectedRoomName}
            onChange={(_, data) => {
              const index = tableData.rooms.findIndex((room) => room.name === data.value);
              setSelectedRoomIndex(index);
              setSelectedRoomName(data.value);
              if (
                tableData.rooms.filter(
                  (room, idx) =>
                    index !== idx &&
                    room.tables.filter((table) => table.bookings && table.bookings.length > 0)
                      .length > 0
                ).length > 0
              ) {
                setOtherRoomWithNewBookings(true);
              } else {
                setOtherRoomWithNewBookings(false);
              }
            }}
          />
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 15,
              paddingRight: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                fontWeight: 600,
                fontSize: 13,
              }}
            >
              <div style={{ width: 12, height: 12, backgroundColor: '#7268cd', borderRadius: 6 }} />
              <p>{i18n.t('free')}</p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                fontWeight: 600,
                fontSize: 13,
              }}
            >
              <div
                style={{ width: 12, height: 12, backgroundColor: 'firebrick', borderRadius: 6 }}
              />
              <p>{i18n.t('unpaid')}</p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                fontWeight: 600,
                fontSize: 13,
              }}
            >
              <div style={{ width: 12, height: 12, backgroundColor: 'coral', borderRadius: 6 }} />
              <p>{i18n.t('bookingModalChangeStatusOpen')}</p>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                fontWeight: 600,
                fontSize: 13,
              }}
            >
              <div style={{ width: 12, height: 12, backgroundColor: '#77A1D3', borderRadius: 6 }} />
              <p>{i18n.t('request')}</p>
            </div>
          </div>
        </div>
        <ReactGridLayout
          cols={18}
          rowHeight={25}
          width={950}
          style={{
            backgroundColor: '#7268cd5e',
            borderRadius: 10,
            minHeight: 'calc(100vh - 270px)',
            maxWidth: 2000,
            minWidth: 950,
            marginTop: 10,
          }}
        >
          {layout}
        </ReactGridLayout>
      </>
    );
  };

  const parseBookingsGrid = (bookings) => {
    const statusColors = {
      closed: 'green',
      open: 'orange',
      refund: 'crimson',
      cancelled: 'red',
    };
    const bookedItems = (bookedItems, paymentType, status, wasPayLater) => {
      const cleanedUpBookedItems = bookedItems.map((item) => {
        const newItem = {
          itemTitle: item.itemTitle,
          categoryName: item.categoryName,
          specialSelections: item.specialSelections.map((special) => ({
            specialSelectionId: special.specialSelectionId,
            specialSelectionTitle: special.specialSelectionTitle,
            specialSelectionPrice: special.specialSelectionPrice,
          })),
          options:
            item.options && item.options.length > 0
              ? item.options.map((option) => ({ name: option.name, price: option.price }))
              : null,
          amount: item.amount,
          taxRate: item.taxRate,
          isRefund: item.isRefund,
        };
        if (item.discount)
          newItem.discount = { amount: item.discount.amount, percentage: item.discount.percentage };
        if (item.notes) newItem.notes = item.notes;
        return newItem;
      });
      return (
        <>
          {bookedItems.map((item, index) => (
            <div key={`${index}1div`}>
              {index ===
              findLastIndex(cleanedUpBookedItems, (element) =>
                isEqual(element, cleanedUpBookedItems[index])
              ) ? (
                <>
                  <li>
                    <span style={{ position: 'relative', left: '-5px', fontWeight: 500 }}>
                      <span style={{ fontWeight: 700 }}>
                        {`${countElement(bookedItems, index)} × `}
                      </span>
                      {`${item.itemTitle} `}
                      {item.isRefund && status !== 'cancelled' && item.isCancelled === true && (
                        <>
                          <span
                            style={{
                              backgroundColor: 'red',
                              color: 'white',
                              border: `solid 1px red`,
                              borderRadius: '4px',
                              padding: 3,
                              paddingBottom: 3,
                              textAlign: 'center',
                              fontWeight: '700',
                              fontSize: '10px',
                            }}
                          >
                            S
                          </span>
                        </>
                      )}
                      {item.isRefund &&
                        paymentType !== 'later' &&
                        (item.isCancelled == null || item.isCancelled === false) && (
                          <>
                            <span
                              style={{
                                backgroundColor: 'red',
                                color: 'white',
                                border: `solid 1px red`,
                                borderRadius: '4px',
                                padding: 3,
                                paddingBottom: 3,
                                textAlign: 'center',
                                fontWeight: '700',
                                fontSize: '10px',
                              }}
                            >
                              E
                            </span>
                          </>
                        )}
                    </span>
                  </li>
                  <ul style={{ position: 'relative', left: 10, width: '90%' }}>
                    {item.notes && (
                      <div
                        style={{
                          display: 'flex',
                          gap: 4,
                          marginLeft: 2,
                          marginBottom: 4,
                          alignItems: 'center',
                        }}
                      >
                        <p className="bookings-extras-options-indicator">H</p>
                        <p
                          style={{
                            marginBottom: 0,
                            fontStyle: 'italic',
                          }}
                        >
                          {` ${item.notes}`}
                        </p>
                      </div>
                    )}
                    {item.options &&
                      item.options.length > 0 &&
                      item.options.map((option, idxx) => (
                        <div
                          key={`opt-${idxx}1`}
                          style={{
                            display: 'flex',
                            gap: 4,
                            marginLeft: 2,
                            marginBottom: 4,
                            alignItems: 'center',
                          }}
                        >
                          <p className="bookings-extras-options-indicator">O</p>
                          <p
                            style={{
                              marginBottom: 0,
                              fontStyle: 'italic',
                            }}
                          >
                            {` ${option.name}`}
                          </p>
                        </div>
                      ))}
                    {item.specialSelections &&
                      item.specialSelections.length > 0 &&
                      item.specialSelections.map((special, idxx) => (
                        <div
                          key={`spec-${idxx}1`}
                          style={{
                            display: 'flex',
                            gap: 4,
                            marginLeft: 2,
                            marginBottom: 4,
                            alignItems: 'center',
                          }}
                        >
                          <p className="bookings-extras-options-indicator">E</p>
                          <p
                            style={{
                              marginBottom: 0,
                              fontStyle: 'italic',
                            }}
                          >
                            {` ${special.specialSelectionTitle}`}
                          </p>
                        </div>
                      ))}
                  </ul>
                </>
              ) : (
                <></>
              )}
            </div>
          ))}
        </>
      );
    };
    return (
      <Fragment key="frag1">
        <Grid stackable columns={3} stretched style={{ paddingBottom: 150, marginTop: 38 }}>
          {bookings &&
            bookings.map((booking) => (
              <Grid.Column
                verticalAlign="top"
                stretched
                key={`gridview${booking._id.toString()}`}
                style={{
                  padding: 10,
                }}
              >
                <div
                  style={{
                    backgroundColor: 'white',
                    borderRadius: 20,
                    boxShadow:
                      booking.isRequest === true
                        ? '#7268cd38 1px 3px 3px 1px'
                        : booking.status === 'open' && booking.isRequest === false
                        ? '#ffa50038 1px 3px 3px 1px'
                        : booking.status === 'closed' && booking.isRequest === false
                        ? '#00800038 1px 3px 3px 1px'
                        : booking.status === 'refund' && booking.isRequest === false
                        ? '#ed143d38 1px 3px 3px 1px'
                        : booking.status === 'cancelled' && booking.isRequest === false
                        ? '#ff000038 1px 3px 3px 1px'
                        : 'rgba(0, 0, 0, 0.10) 1px 3px 3px 1px',
                    transitionProperty: 'box-shadow, transform',
                    height: user.role === 'employee' ? 'unset' : 250,
                    padding: 16,
                    overflowY: 'scroll',
                    transform: 'translateZ(0)',
                    wordBreak: 'break-all',
                    whiteSpace: 'initial',
                  }}
                  className="bookings-grid-container"
                  onClick={() => {
                    setModalOptions({ booking, open: true, closeEditMode: false });
                  }}
                >
                  <p
                    style={{
                      borderRadius: 10,
                      padding: 5,
                      width: '100%',
                      textAlign: 'center',
                      fontWeight: 700,
                      fontSize: 15,
                      whiteSpace: 'initial',
                      wordBreak: 'break-all',
                      color: 'black',
                      border:
                        booking.isRequest === true
                          ? 'solid 2px #7268cd'
                          : `solid 2px ${statusColors[booking.status]}`,
                    }}
                  >
                    {booking.table
                      ? booking.table.tableDescription +
                        (booking.table.tableNumber ? ` (${booking.table.tableNumber})` : '')
                      : '-'}
                  </p>
                  {booking.isRequest === true && (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 6,
                        width: '100%',
                        fontSize: '12px',
                        fontWeight: '800',
                        textAlign: 'center',
                        justifyContent: 'center',
                        marginBottom: 10,
                      }}
                    >
                      <span
                        style={{
                          color: '#7268cd',
                          border: `solid 2px #7268cd`,
                          borderRadius: '8px',
                          boxSizing: 'border-box',
                          display: 'inline-block',
                          padding: '2px',
                          paddingLeft: '5px',
                          paddingRight: '5px',
                          height: 27,
                        }}
                      >
                        {i18n.t('request')}
                      </span>
                    </div>
                  )}
                  {booking.isRequest === false && (
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 6,
                        width: '100%',
                        fontSize: '11px',
                        fontWeight: '800',
                        textAlign: 'center',
                        justifyContent: 'center',
                        marginBottom: 10,
                      }}
                    >
                      <span
                        style={{
                          color: statusColors[booking.status],
                          border: `solid 1px ${statusColors[booking.status]}`,
                          borderRadius: '8px',
                          boxSizing: 'border-box',
                          display: 'inline-block',
                          padding: '2px',
                          paddingLeft: '4px',
                          paddingRight: '4px',
                          height: 25,
                        }}
                      >
                        {booking.status === 'open'
                          ? i18n.t('bookingModalChangeStatusOpen').toUpperCase()
                          : booking.status === 'closed'
                          ? i18n.t('bookingModalChangeStatusDone').toUpperCase()
                          : booking.status === 'refund'
                          ? i18n.t('bookingsRefunded').toUpperCase()
                          : i18n.t('bookingsStatusStorniert').toUpperCase()}
                      </span>
                      <span
                        style={{
                          color:
                            booking.paymentType === 'later'
                              ? 'maroon'
                              : booking.paymentType === 'cash'
                              ? 'steelblue'
                              : 'royalblue',
                          border: `solid 1px ${
                            booking.paymentType === 'later'
                              ? 'maroon'
                              : booking.paymentType === 'cash'
                              ? 'steelblue'
                              : 'royalblue'
                          }`,
                          borderRadius: '8px',
                          boxSizing: 'border-box',
                          display: 'inline-block',
                          padding: '2px',
                          paddingLeft: '4px',
                          paddingRight: '4px',
                          height: 25,
                        }}
                      >
                        {booking.paymentType === 'later'
                          ? i18n.t('bookingsSaved').toUpperCase()
                          : booking.paymentType === 'cash'
                          ? i18n.t('dailySummaryBar').toUpperCase()
                          : i18n.t('dailySummaryKarte').toUpperCase()}
                      </span>
                      <span
                        style={{
                          color: booking.paid ? 'mediumseagreen' : 'red',
                          border: `solid 1px ${booking.paid ? 'mediumseagreen' : 'red'}`,
                          borderRadius: '8px',
                          boxSizing: 'border-box',
                          display: 'inline-block',
                          padding: '2px',
                          paddingLeft: '4px',
                          paddingRight: '4px',
                          height: 25,
                        }}
                      >
                        {booking.paid
                          ? i18n.t('bookingPaymentPaid').toUpperCase()
                          : i18n.t('bookingPaymentUnpaid').toUpperCase()}
                      </span>
                      {booking.table && (
                        <span
                          style={{
                            color:
                              booking.table && booking.table.isTakeAway === 'true'
                                ? 'pink'
                                : 'plum',
                            border: `solid 1px ${
                              booking.table && booking.table.isTakeAway === 'true' ? 'pink' : 'plum'
                            }`,
                            borderRadius: '8px',
                            boxSizing: 'border-box',
                            display: 'inline-block',
                            padding: '2px',
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            height: 25,
                          }}
                        >
                          {booking.table && booking.table.isTakeAway === 'true'
                            ? 'TAKE-AWAY'
                            : 'IN-HOUSE'}
                        </span>
                      )}
                      {booking.isFromTerminal === true && (
                        <span
                          style={{
                            color: 'black',
                            border: 'solid 1px black',
                            borderRadius: '8px',
                            boxSizing: 'border-box',
                            display: 'inline-block',
                            padding: '2px',
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            height: 25,
                          }}
                        >
                          TERMINAL
                        </span>
                      )}
                      {booking.isFromTerminal === false && (
                        <span
                          style={{
                            color: 'black',
                            border: 'solid 1px black',
                            borderRadius: '8px',
                            boxSizing: 'border-box',
                            display: 'inline-block',
                            padding: '2px',
                            paddingLeft: '4px',
                            paddingRight: '4px',
                            height: 25,
                          }}
                        >
                          QR
                        </span>
                      )}
                    </div>
                  )}
                  <p
                    style={{
                      bottom: 0,
                      width: '100%',
                      textAlign: 'center',
                      fontWeight: 500,
                      fontSize: 12,
                    }}
                  >
                    {moment(booking.created.toString()).format(
                      `HH:mm[${i18n.t('uhrGerman')}] · DD.MM.YYYY`
                    )}
                  </p>
                  <div style={{ marginTop: 12, marginBottom: 12 }}>
                    {bookedItems(
                      booking.bookedItems,
                      booking.paymentType,
                      booking.status,
                      booking.wasPayLater
                    )}
                  </div>
                  <div style={{ width: '100%', height: 1, backgroundColor: '#999' }} />
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: 22,
                      marginTop: 10,
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    {`${asCurrency(booking.amount / 100)} €`}
                  </p>
                </div>
              </Grid.Column>
            ))}
        </Grid>
      </Fragment>
    );
  };

  const parseBookings = (bookings) => {
    const statusColors = {
      closed: 'green',
      open: 'orange',
      refund: 'crimson',
      cancelled: 'red',
    };
    const bookedItems = (bookedItems, paymentType, status, wasPayLater) => {
      const cleanedUpBookedItems = bookedItems.map((item) => {
        const newItem = {
          itemTitle: item.itemTitle,
          categoryName: item.categoryName,
          specialSelections: item.specialSelections.map((special) => ({
            specialSelectionId: special.specialSelectionId,
            specialSelectionTitle: special.specialSelectionTitle,
            specialSelectionPrice: special.specialSelectionPrice,
          })),
          options:
            item.options && item.options.length > 0
              ? item.options.map((option) => ({ name: option.name, price: option.price }))
              : null,
          amount: item.amount,
          taxRate: item.taxRate,
          isRefund: item.isRefund,
        };
        if (item.discount)
          newItem.discount = { amount: item.discount.amount, percentage: item.discount.percentage };
        if (item.notes) newItem.notes = item.notes;
        return newItem;
      });
      return (
        <>
          {bookedItems.map((item, index) => (
            <div key={`${index}1div`}>
              {index ===
              findLastIndex(cleanedUpBookedItems, (element) =>
                isEqual(element, cleanedUpBookedItems[index])
              ) ? (
                <>
                  <li>
                    <span style={{ position: 'relative', left: '-5px', fontWeight: 500 }}>
                      <span style={{ fontWeight: 700 }}>
                        {`${countElement(bookedItems, index)} × `}
                      </span>
                      {`${item.itemTitle} `}
                      {item.isRefund && status !== 'cancelled' && item.isCancelled === true && (
                        <>
                          <span
                            style={{
                              backgroundColor: 'red',
                              color: 'white',
                              border: `solid 1px red`,
                              borderRadius: '4px',
                              padding: 3,
                              paddingBottom: 3,
                              textAlign: 'center',
                              fontWeight: '700',
                              fontSize: '10px',
                            }}
                          >
                            S
                          </span>
                        </>
                      )}
                      {item.isRefund &&
                      paymentType !== 'later' &&
                      (item.isCancelled == null || item.isCancelled === false) ? (
                        <>
                          <span
                            style={{
                              backgroundColor: 'red',
                              color: 'white',
                              border: `solid 1px red`,
                              borderRadius: '4px',
                              padding: 3,
                              paddingBottom: 3,
                              textAlign: 'center',
                              fontWeight: '700',
                              fontSize: '10px',
                            }}
                          >
                            E
                          </span>
                        </>
                      ) : (
                        <></>
                      )}
                    </span>
                  </li>
                  <ul style={{ position: 'relative', left: 10 }}>
                    {item.notes && (
                      <div
                        style={{
                          display: 'flex',
                          gap: 4,
                          marginLeft: 2,
                          marginBottom: 4,
                          alignItems: 'center',
                        }}
                      >
                        <p className="bookings-extras-options-indicator">H</p>
                        <p
                          style={{
                            marginBottom: 0,
                            fontStyle: 'italic',
                          }}
                        >
                          {` ${item.notes}`}
                        </p>
                      </div>
                    )}
                    {item.options &&
                      item.options.length > 0 &&
                      item.options.map((option, idx) => (
                        <div
                          key={`opt-${idx}2`}
                          style={{
                            display: 'flex',
                            gap: 4,
                            marginLeft: 2,
                            marginBottom: 4,
                            alignItems: 'center',
                          }}
                        >
                          <p className="bookings-extras-options-indicator">O</p>
                          <p
                            style={{
                              marginBottom: 0,
                              fontStyle: 'italic',
                            }}
                          >
                            {` ${option.name}`}
                          </p>
                        </div>
                      ))}
                    {item.specialSelections &&
                      item.specialSelections.length > 0 &&
                      item.specialSelections.map((special, idx) => (
                        <div
                          key={`spec-${idx}2`}
                          style={{
                            display: 'flex',
                            gap: 4,
                            marginLeft: 2,
                            marginBottom: 4,
                            alignItems: 'center',
                          }}
                        >
                          <p className="bookings-extras-options-indicator">E</p>
                          <p
                            style={{
                              marginBottom: 0,
                              fontStyle: 'italic',
                            }}
                          >
                            {` ${special.specialSelectionTitle}`}
                          </p>
                        </div>
                      ))}
                  </ul>
                </>
              ) : (
                <></>
              )}
            </div>
          ))}
        </>
      );
    };
    return bookings.map((booking) => (
      <Fragment key={`${booking._id.toString()}frag`}>
        <Table.Row
          key={booking._id}
          onClick={() => {
            if (isLoadingBookings === false) {
              setModalOptions({ booking, open: true, closeEditMode: false });
            }
          }}
          style={{
            backgroundColor: booking.isRequest === true ? '#7268cd1f' : '',
          }}
          color="teal"
        >
          <Table.Cell
            key={`${booking._id}1`}
            collapsing
            textAlign="center"
            style={{
              textOverflow: 'unset',
              border: booking.isRequest === true ? 'none' : '',
            }}
          >
            {booking.isRequest === true && (
              <span
                style={{
                  color: '#7268cd',
                  border: `solid 2px #7268cd`,
                  borderRadius: '8px',
                  boxSizing: 'border-box',
                  display: 'inline-block',
                  padding: '2px',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  textAlign: 'center',
                  fontWeight: '700',
                  fontSize: '12px',
                }}
              >
                {i18n.t('request').toUpperCase()}
              </span>
            )}
            {booking.isRequest === false && (
              <span
                style={{
                  color: statusColors[booking.status],
                  border: `solid 1px ${statusColors[booking.status]}`,
                  borderRadius: '8px',
                  boxSizing: 'border-box',
                  display: 'inline-block',
                  padding: '2px',
                  paddingLeft: '4px',
                  paddingRight: '4px',
                  textAlign: 'center',
                  fontWeight: '700',
                  fontSize: '12px',
                }}
              >
                {booking.status === 'open'
                  ? i18n.t('bookingModalChangeStatusOpen').toUpperCase()
                  : booking.status === 'closed'
                  ? i18n.t('bookingModalChangeStatusDone').toUpperCase()
                  : booking.status === 'refund'
                  ? i18n.t('bookingsRefunded').toUpperCase()
                  : i18n.t('bookingsStatusStorniert').toUpperCase()}
              </span>
            )}
          </Table.Cell>
          <Table.Cell
            key={`${booking._id}2`}
            style={{
              border: booking.isRequest === true ? 'none' : '',
            }}
          >
            {bookedItems(
              booking.bookedItems,
              booking.paymentType,
              booking.status,
              booking.wasPayLater
            )}
          </Table.Cell>
          <Table.Cell
            key={`${booking._id}3`}
            collapsing
            textAlign="center"
            style={{
              border: booking.isRequest === true ? 'none' : '',
            }}
          >
            <p
              style={{
                fontWeight: 600,
                fontSize: 14,
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
              }}
            >
              {booking.table
                ? booking.table.tableDescription +
                  (booking.table.tableNumber ? ` (${booking.table.tableNumber})` : '')
                : '-'}
            </p>
          </Table.Cell>
          <Table.Cell
            key={`${booking._id}4`}
            collapsing
            textAlign="center"
            style={{
              border: booking.isRequest === true ? 'none' : '',
            }}
          >
            <p style={{ fontWeight: 600, fontSize: 16 }}>
              {`${asCurrency(booking.amount / 100)} €`}
            </p>
          </Table.Cell>
          <Table.Cell
            key={`${booking._id}5`}
            collapsing
            textAlign="center"
            style={{
              textOverflow: 'unset',
              border: booking.isRequest === true ? 'none' : '',
            }}
          >
            {booking.isRequest === true && (
              <span
                style={{
                  color: '#7268cd',
                  border: `solid 2px #7268cd`,
                  borderRadius: '8px',
                  boxSizing: 'border-box',
                  display: 'inline-block',
                  padding: '2px',
                  paddingLeft: '5px',
                  paddingRight: '5px',
                  textAlign: 'center',
                  fontWeight: '700',
                  fontSize: '12px',
                }}
              >
                {i18n.t('request').toUpperCase()}
              </span>
            )}
            {booking.isRequest === false && (
              <span
                style={{
                  color: booking.paid ? 'mediumseagreen' : 'red',
                  border: `solid 1px ${booking.paid ? 'mediumseagreen' : 'red'}`,
                  borderRadius: '8px',
                  boxSizing: 'border-box',
                  display: 'inline-block',
                  padding: '2px',
                  paddingLeft: '4px',
                  paddingRight: '4px',
                  textAlign: 'center',
                  fontWeight: '700',
                  fontSize: '12px',
                }}
              >
                {booking.paid
                  ? i18n.t('bookingPaymentPaid').toUpperCase()
                  : i18n.t('bookingPaymentUnpaid').toUpperCase()}
              </span>
            )}
          </Table.Cell>
          <Table.Cell
            key={`${booking._id}6`}
            style={{
              border: booking.isRequest === true ? 'none' : '',
            }}
          >
            {moment(booking.created.toString()).format(`HH:mm[${i18n.t('uhrGerman')}], DD.MM.YYYY`)}
          </Table.Cell>
        </Table.Row>
      </Fragment>
    ));
  };

  const searchHandler = (value) => handleSearch(value);

  const debouncedSearchHandler = useMemo(() => debounce(searchHandler, 150), []);

  const renderToolbar = () => (
    <>
      <img
        src={refreshIcon}
        alt=""
        style={{ cursor: 'pointer', width: 25, height: 25 }}
        onClick={async () => {
          await onRefreshShop();
          await getBookings(token, 1, 'created', 'descending', null);
        }}
      />
      <p style={{ color: '#7268cd', fontWeight: 500, paddingTop: 2 }}>|</p>
      {mobileMatches === true ? (
        <>
          <img
            src={
              showGridView === true && matches === true && user.role === 'manager'
                ? tableIconUnselected
                : showTableView === true
                ? listViewIcon
                : showGridView === false && showTableView === false
                ? gridViewIcon
                : listViewIcon
            }
            alt=""
            style={{ cursor: 'pointer', width: 25, height: 25 }}
            onClick={() => {
              if (showGridView === true) {
                setShowGridView(false);
              }
              if (showTableView === true) {
                setShowTableView(false);
              }
              if (showGridView === false && showTableView === false) {
                setShowGridView(true);
              }
              if (
                showGridView === true &&
                showTableView === false &&
                matches === true &&
                user.role === 'manager'
              ) {
                setRefreshFromModalNeeded(true);
                setIsGettingBookings(false);
                setTableData({ bookings: [] });
                setShowTableView(true);
                setShowGridView(false);
              }
            }}
          />
          <p style={{ color: '#7268cd', fontWeight: 500, paddingTop: 2 }}>|</p>
        </>
      ) : (
        <></>
      )}
      <img
        src={summaryIcon}
        alt=""
        style={{ cursor: 'pointer', width: 25, height: 25 }}
        onClick={() => {
          if (shop.isPinRequired === true && user.role !== 'owner') {
            setPinOptions({
              open: true,
              onSendPin: async (pin) => {
                try {
                  const res = await checkPinOnly(token, pin);
                  if (res && res.success) {
                    onClosePinModal();
                    setDailySummaryModalOptions({ open: true, role: res.role, pin });
                  } else {
                    setErrorModalOptions({
                      open: true,
                      title: i18n.t('error'),
                      message: i18n.t('pinIncorrectError'),
                      ok: true,
                      onOk: () => closeErrorModal(),
                      yes: false,
                      onYes: null,
                      no: false,
                      onNo: null,
                    });
                    onClosePinModal();
                  }
                } catch (error) {
                  setErrorModalOptions({
                    open: true,
                    title: i18n.t('error'),
                    message: i18n.t('pinError'),
                    ok: true,
                    onOk: () => closeErrorModal(),
                    yes: false,
                    onYes: null,
                    no: false,
                    onNo: null,
                  });
                  onClosePinModal();
                }
              },
            });
          } else {
            setDailySummaryModalOptions({ open: true, role: user.role, pin: '' });
          }
        }}
      />
      <p style={{ color: '#7268cd', fontWeight: 500, paddingTop: 2 }}>|</p>
      {shop && shop.terminalReaders && shop.terminalReaders.length > 0 && user.role !== 'employee' && (
        <>
          <div className="not-mobile">
            <div
              style={{
                height: 10,
                width: 10,
                borderRadius: 4,
                backgroundColor:
                  stripeTerminal && stripeTerminal.connectionStatus === 'connected'
                    ? 'mediumseagreen'
                    : isDiscoveringTerminal === true
                    ? 'orange'
                    : 'red',
                marginLeft: 16,
                marginTop: -4,
              }}
            />
            <img
              className="not-mobile"
              src={readerIcon}
              alt=""
              style={{
                marginTop: -6,
                width: 25,
                height: 25,
                cursor: 'pointer',
              }}
              onClick={() => {
                if (isDiscoveringTerminal === false) {
                  setTerminalReaderModalOptions({
                    open: true,
                    terminals: discoveredTerminals,
                    connectedReader: stripeTerminal,
                    isConnectingToTerminal: false,
                  });
                } else {
                  showToast({
                    loading: true,
                    message: `Nach Kartenlesegeräten suchen.`,
                    id: 'searchingTerminal',
                  });
                }
              }}
            />
          </div>
          <p
            className="not-mobile"
            style={{
              color: '#7268cd',
              fontWeight: 500,
              paddingTop: 2,
            }}
          >
            |
          </p>
        </>
      )}
      {user && user.role === 'manager' && user.isPrinting === true ? (
        <>
          <div className="not-mobile">
            <div
              style={{
                height: 10,
                width: 10,
                borderRadius: 4,
                backgroundColor: failedPrints.length > 0 ? 'red' : 'mediumseagreen',
                marginLeft: 16,
                marginTop: -4,
              }}
            />
            <img
              className="not-mobile"
              src={printerIcon}
              alt=""
              style={{
                marginTop: -6,
                width: 25,
                height: 25,
                cursor: 'pointer',
              }}
              onClick={() => {
                setFailedPrintsModalOptions({ open: true });
              }}
            />
          </div>
          <p
            className="not-mobile"
            style={{
              color: '#7268cd',
              fontWeight: 500,
              paddingTop: 2,
            }}
          >
            |
          </p>
        </>
      ) : (
        <></>
      )}
      <img
        src={mergeIcon}
        alt=""
        style={{ cursor: 'pointer', width: 26, height: 26, marginTop: -1 }}
        onClick={async () => {
          setBookingsMergeModalOptions({ open: true });
        }}
      />
      {mobileMatches === true ? (
        <>
          <p style={{ color: '#7268cd', fontWeight: 500, paddingTop: 2 }} className="not-mobile">
            |
          </p>
          <img
            className="not-mobile"
            src={newbookingIcon}
            alt=""
            style={{ cursor: 'pointer', width: 26, height: 26, marginTop: -1 }}
            onClick={() => {
              setBookingsCreateModalOptions({
                open: true,
                terminal:
                  stripeTerminal && stripeTerminal.connectionStatus === 'connected'
                    ? stripeTerminal
                    : null,
                table: null,
                bookingId: null,
                isDiscoveringTerminal,
              });
            }}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );

  return (
    <>
      <div className="cockpit-title">
        <p className="cockpit-title" style={{ marginLeft: user.role !== 'owner' ? '0' : '' }}>
          {i18n.t('bookingsTitle')}
        </p>
        <p
          className={
            user.role === 'employee' || user.role === 'manager'
              ? 'cockpit-subtitle-employee'
              : 'cockpit-subtitle'
          }
        >
          {i18n.t('bookingsSubTitle')}
        </p>
      </div>
      {user.role === 'employee' ? (
        <>
          <div className="bookings-toolbar" style={{ marginRight: 28 }}>
            <img
              src={refreshIcon}
              alt=""
              style={{ cursor: 'pointer', width: 25, height: 25 }}
              onClick={async () => {
                await onRefreshShop();
                await getBookings(token, 1, 'created', 'descending', null);
              }}
            />
            {(user.isOrdering === true || user.isPaying === true) && (
              <>
                <p style={{ color: '#7268cd', fontWeight: 500, paddingTop: 2 }}>|</p>
                <img
                  src={mergeIcon}
                  alt=""
                  style={{ cursor: 'pointer', width: 26, height: 26, marginTop: -1 }}
                  onClick={async () => {
                    setBookingsMergeModalOptions({ open: true });
                  }}
                />
                <p style={{ color: '#7268cd', fontWeight: 500, paddingTop: 2 }}>|</p>
                <img
                  src={newbookingIcon}
                  alt=""
                  style={{ cursor: 'pointer', width: 26, height: 26, marginTop: -1 }}
                  onClick={() => {
                    if (user.role === 'employee' && shop.tables && shop.tables.length === 0) {
                      showToast({
                        error: true,
                        message: i18n.t('noTableAvailable'),
                        title: i18n.t('newPrinterErrorTitle'),
                      });
                    } else {
                      setBookingsCreateModalOptions({
                        open: true,
                        table: null,
                        bookingId: null,
                        isDiscoveringTerminal,
                      });
                    }
                  }}
                />
              </>
            )}
          </div>
        </>
      ) : (
        <div
          className="bookings-toolbar"
          style={{ marginRight: user.role === 'manager' ? '28px' : '' }}
        >
          {renderToolbar()}
        </div>
      )}
      <div
        className={
          user.role === 'employee' || user.role === 'manager'
            ? showGridView === true
              ? 'bookings-container-employee-grid'
              : showTableView === true
              ? 'bookings-container-table'
              : 'bookings-container-employee'
            : showGridView === true
            ? 'bookings-container-grid'
            : 'bookings-container'
        }
      >
        <Grid columns={1}>
          <Grid.Column key="maingrid">
            {showTableView === false && (
              <div className="bookings-header-container">
                <div className="bookings-search">
                  <Search
                    placeholder={i18n.t('bookingSearch')}
                    className="bookings-searchbar"
                    onSearchChange={(_, data) => debouncedSearchHandler(data.value)}
                    open={false}
                    loading={isSearchLoading}
                    value={searchValue}
                  />
                </div>
                <div className="bookings-pagination">
                  <div className="bookings-pagination-center">
                    <Pagination
                      prevItem="<"
                      nextItem=">"
                      activePage={tableData ? tableData.currentPage : 1}
                      totalPages={
                        tableData && tableData.totalPages ? Math.ceil(tableData.totalPages) : 0
                      }
                      ellipsisItem={null}
                      firstItem={null}
                      lastItem={null}
                      siblingRange={1}
                      boundaryRange={0}
                      onPageChange={(e, props) => handlePageChange(e, props)}
                      pointing
                      secondary
                    />
                  </div>
                </div>
                <div className="bookings-dropdown">
                  <Dropdown
                    selection
                    clearable
                    className="bookings-dropdown-min-height"
                    placeholder={i18n.t('bookingsFilter')}
                    options={dropdownOptions}
                    value={selectedDropdownOptions}
                    onChange={(_, data) => {
                      handleFilterChange(data.value);
                      onDropDownChange(data.value);
                    }}
                  />
                </div>
              </div>
            )}
            {showGridView === false && showTableView === false && (
              <div
                className={
                  user.role === 'employee' || user.role === 'manager'
                    ? 'bookings-table-container-employee'
                    : 'bookings-table-container'
                }
              >
                <Table fixed celled selectable padded sortable className="bookings-table">
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        width="2"
                        sorted={
                          tableData
                            ? tableData.selectedColumn === 'status'
                              ? tableData.sortDirection
                              : null
                            : null
                        }
                        onClick={() => handleSort('status')}
                      >
                        {i18n.t('bookingsStatus')}
                      </Table.HeaderCell>
                      <Table.HeaderCell>{i18n.t('bookingsItems')}</Table.HeaderCell>
                      <Table.HeaderCell
                        width="2"
                        sorted={
                          tableData
                            ? tableData.selectedColumn === 'table'
                              ? tableData.sortDirection
                              : null
                            : null
                        }
                        onClick={() => handleSort('table')}
                      >
                        {i18n.t('bookingsTable')}
                      </Table.HeaderCell>
                      <Table.HeaderCell
                        width="2"
                        sorted={
                          tableData
                            ? tableData.selectedColumn === 'amount'
                              ? tableData.sortDirection
                              : null
                            : null
                        }
                        onClick={() => handleSort('amount')}
                      >
                        {i18n.t('bookingsPrice')}
                      </Table.HeaderCell>
                      <Table.HeaderCell width="2">{i18n.t('bookingsPayment')}</Table.HeaderCell>
                      <Table.HeaderCell
                        width="2"
                        sorted={
                          tableData
                            ? tableData.selectedColumn === 'created'
                              ? tableData.sortDirection
                              : null
                            : null
                        }
                        onClick={() => handleSort('created')}
                      >
                        {i18n.t('bookingsDate')}
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body key="table.body">
                    <>
                      {showGridView === false && showTableView === false && tableData
                        ? parseBookings(
                            tableData.bookings && tableData.bookings.length > 0
                              ? tableData.bookings
                              : []
                          )
                        : []}
                      <Loader
                        active={isLoadingBookings === true}
                        size="large"
                        style={{ marginTop: -100 }}
                        className="bookings-create-loader"
                      />
                    </>
                  </Table.Body>
                </Table>
              </div>
            )}
            {showGridView && isLoadingBookings ? (
              <>
                <div style={{ marginTop: 300 }}>
                  <Loader
                    active={isLoadingBookings === true}
                    size="medium"
                    className="bookings-create-loader"
                  />
                </div>
              </>
            ) : (
              <>
                {showGridView && showTableView === false && tableData
                  ? parseBookingsGrid(tableData.bookings)
                  : []}
              </>
            )}
            {showTableView ? (
              <>
                {showTableView && showGridView === false && tableData ? parseTableView() : []}
                <Loader
                  active={isLoadingBookings === true}
                  size="large"
                  className="bookings-create-loader"
                />
              </>
            ) : (
              <></>
            )}
          </Grid.Column>
        </Grid>
        <ErrorWarningModal
          open={warningModalOptions.open}
          title={warningModalOptions.title}
          message={warningModalOptions.message}
          ok={warningModalOptions.ok}
          onOk={warningModalOptions.onOk}
          yes={warningModalOptions.yes}
          onYes={warningModalOptions.onYes}
          no={warningModalOptions.no}
          onNo={warningModalOptions.onNo}
          amount={warningModalOptions.amount}
        />
        <ErrorWarningModal
          open={errorModalOptions.open}
          title={errorModalOptions.title}
          message={errorModalOptions.message}
          ok={errorModalOptions.ok}
          onOk={errorModalOptions.onOk}
          yes={errorModalOptions.yes}
          onYes={errorModalOptions.onYes}
          no={errorModalOptions.no}
          onNo={errorModalOptions.onNo}
        />
        <Modal dimmer="blurring" size="tiny" open={errorWarningModalOptions.open}>
          <Modal.Header style={{ borderBottom: 'none' }}>
            <p>{i18n.t('newPrinterErrorTitle')}</p>
          </Modal.Header>
          <Modal.Content className="unselectable">
            <p style={{ fontWeight: 600 }}>{errorWarningModalOptions.message}</p>
          </Modal.Content>
          <Modal.Actions style={{ borderTop: 'none', backgroundColor: 'white' }}>
            <Button content="OK" color="teal" onClick={onErrorWarningModalOk} />
          </Modal.Actions>
        </Modal>
        <BookingsScreenModal
          open={modalOptions.open}
          booking={modalOptions.booking}
          onClose={onCloseModal}
          onRefund={onRefund}
          onChangeStatus={onChangeStatus}
          onSaveEditing={onSaveEditing}
          closeEditMode={modalOptions.closeEditMode}
          onPayLater={onPayLater}
          onSaveSplitting={onSaveSplitting}
          onSaveEditingTable={onSaveEditingTable}
          onAcceptingRequest={onAcceptingRequest}
          onDecliningRequest={onDecliningRequest}
          logoCanvasContext={
            logoError == null && logoCanvasContext != null ? logoCanvasContext : null
          }
        />
        <DailySummaryModal
          open={dailySummaryModalOptions.open}
          role={dailySummaryModalOptions.role}
          pin={dailySummaryModalOptions.pin}
          onClose={onCloseDailySummaryModal}
        />
        <BookingsMergeModal
          open={bookingsMergeModalOptions.open}
          onClose={onCloseBookingsMergeModal}
        />
        <PinNumPad
          open={pinOptions.open}
          onClose={onClosePinModal}
          onSendPin={pinOptions.onSendPin}
        />
        <Modal dimmer="blurring" size="small" open={bookingsListModalOptions.open}>
          <Modal.Header style={{ borderBottom: 'white', backgroundColor: '#F5F5F9' }}>
            {`${i18n.t('bookingsTable')} ${bookingsListModalOptions.table}`}
            <Button
              icon="cancel"
              color="black"
              basic
              onClick={() => {
                setBookingsListModalOptions({ open: false, table: null, bookings: [] });
              }}
              floated="right"
              circular
              size="big"
              className="tables-close-btn"
            />
          </Modal.Header>
          <Modal.Content scrolling style={{ paddingTop: 10, backgroundColor: '#f5f5f9' }}>
            <Grid stackable doubling columns={3}>
              {bookingsListModalOptions.open === true &&
                bookingsListModalOptions.bookings.length > 0 && (
                  <>
                    {sortBy(bookingsListModalOptions.bookings, [
                      (booking) => booking.isRequest === false,
                      (booking) => booking.paid === true,
                    ]).map((booking) => (
                      <Fragment key="bklsmopir-false">
                        <Grid.Column style={{ padding: 10, cursor: 'pointer' }}>
                          <div
                            style={{
                              padding: 10,
                              borderRadius: 12,
                              fontWeight: 700,
                              textAlign: 'center',
                              fontSize: 15,
                              backgroundColor: 'white',
                              color:
                                booking.paid === false && booking.isRequest === false
                                  ? '#b22222'
                                  : booking.isRequest === true
                                  ? '#77a1d3'
                                  : '#ff7f50',
                              border: `2px solid ${
                                booking.paid === false && booking.isRequest === false
                                  ? '#b22222'
                                  : booking.isRequest === true
                                  ? '#77a1d3'
                                  : '#ff7f50'
                              }`,
                            }}
                            onClick={() => {
                              setBookingsListModalOptions({
                                open: false,
                                bookings: [],
                                table: null,
                              });
                              if (booking.paid === false && booking.isRequest === false) {
                                setBookingsCreateModalOptions({
                                  open: true,
                                  terminal:
                                    stripeTerminal &&
                                    stripeTerminal.connectionStatus === 'connected'
                                      ? stripeTerminal
                                      : null,
                                  table: booking.table.tableId,
                                  bookingId: booking.bookingId,
                                  isDiscoveringTerminal,
                                });
                              } else {
                                setModalOptions({
                                  open: true,
                                  booking,
                                  closeEditMode: false,
                                });
                              }
                            }}
                          >
                            <p>
                              {`${
                                booking.paid === false
                                  ? i18n.t('orderTitle')
                                  : i18n.t('bookingModalTitle')
                              }  ${booking.bookingId}`}
                            </p>
                            <p style={{ color: 'black' }}>
                              {`${asCurrency(booking.amount / 100)}€`}
                            </p>
                          </div>
                        </Grid.Column>
                      </Fragment>
                    ))}
                  </>
                )}
            </Grid>
          </Modal.Content>
          <Modal.Actions style={{ borderTop: 'none', height: 55, backgroundColor: '#F5F5F9' }}>
            {bookingsListModalOptions.bookings.filter(
              (booking) => booking.status === 'open' && booking.paid === true
            ).length > 0 && (
              <Button
                content={i18n.t('closeOpen')}
                color="orange"
                floated="left"
                onClick={() => {
                  onCloseAll(
                    tableData.rooms[selectedRoomIndex].tables.find(
                      (table) => table.number === bookingsListModalOptions.table
                    ).tableId
                  );
                }}
              />
            )}
            {bookingsListModalOptions.bookings.filter(
              (booking) => booking.paid === false && booking.isRequest === false
            ).length > 0 && (
              <Button
                content={i18n.t('payUnpaid')}
                color="teal"
                floated="right"
                onClick={() => {
                  setBookingsListModalOptions({ open: false, bookings: [], table: null });
                  setBookingsCreateModalOptions({
                    open: true,
                    isDiscoveringTerminal,
                    terminal:
                      stripeTerminal && stripeTerminal.connectionStatus === 'connected'
                        ? stripeTerminal
                        : null,
                    table: tableData.rooms[selectedRoomIndex].tables.find(
                      (table) => table.number === bookingsListModalOptions.table
                    ).tableId,
                    bookingId:
                      bookingsListModalOptions.bookings.filter(
                        (booking) => booking.paid === false && booking.isRequest === false
                      ).length === 1
                        ? bookingsListModalOptions.bookings.filter(
                            (booking) => booking.paid === false && booking.isRequest === false
                          )[0].bookingId
                        : null,
                  });
                }}
              />
            )}
          </Modal.Actions>
        </Modal>
        {user && user.role === 'employee' ? (
          <>
            <BookingsCreateModalMobile
              open={bookingsCreateModalOptions.open}
              onClose={onCloseBookingsCreateModal}
              tableId={bookingsCreateModalOptions.table}
              onBackgroundRefresh={onBackgroundRefresh}
              bookingId={bookingsCreateModalOptions.bookingId}
              logoCanvasContext={
                logoError == null && logoCanvasContext != null ? logoCanvasContext : null
              }
              resetBookingId={() => {
                setBookingsCreateModalOptions({
                  ...bookingsCreateModalOptions,
                  bookingId: null,
                  isDiscoveringTerminal,
                });
              }}
            />
          </>
        ) : (
          <>
            <BookingsCreateModal
              open={bookingsCreateModalOptions.open}
              onClose={onCloseBookingsCreateModal}
              terminal={stripeTerminal}
              tableId={bookingsCreateModalOptions.table}
              onBackgroundRefresh={onBackgroundRefresh}
              bookingId={bookingsCreateModalOptions.bookingId}
              isDiscoveringTerminal={bookingsCreateModalOptions.isDiscoveringTerminal}
              logoCanvasContext={
                logoError == null && logoCanvasContext != null ? logoCanvasContext : null
              }
              openCardsModal={() => {
                setTerminalReaderModalOptions({
                  open: true,
                  terminals: discoveredTerminals,
                  connectedReader: stripeTerminal,
                  isConnectingToTerminal: false,
                });
              }}
              resetBookingId={() => {
                setBookingsCreateModalOptions({
                  ...bookingsCreateModalOptions,
                  bookingId: null,
                  isDiscoveringTerminal,
                });
              }}
              setTableIdToNull={() => {
                setBookingsCreateModalOptions({ ...bookingsCreateModalOptions, table: null });
              }}
            />
          </>
        )}
        {user && user.role === 'manager' ? (
          <>
            <FailedPrintsModal
              open={failedPrintsModalOptions.open}
              onClose={() => setFailedPrintsModalOptions({ open: false })}
            />
          </>
        ) : (
          <></>
        )}
        {shop && shop.terminalReaders && shop.terminalReaders.length > 0 && (
          <BookingsTerminalReaderModal
            open={terminalReaderModalOptions.open}
            terminals={terminalReaderModalOptions.terminals}
            connectedReader={terminalReaderModalOptions.connectedReader}
            onClose={onCloseTerminalReaderModal}
            onReconnect={onReconnect}
            isConnectingToTerminal={terminalReaderModalOptions.isConnectingToTerminal}
          />
        )}
      </div>
    </>
  );
};
export default BookingsScreen;
